<template>
  <b-modal v-model="afficherDuplication" title="Duplication d'un menu"
    ok-title="Dupliquer" :ok-disabled="duplicationEnCours || !estValide"
    cancel-title="Annuler"
    no-close-on-backdrop
    @show="init()"
    @ok="dupliquer()">
    <template slot="default">
      <div :key="key">
        <h4><b>Menu d'origine</b></h4>
        <div class="form-group">
          <v-select v-model="planMenuOrigineSelectionne" :options="tousLesPlansSource" value="id" label="nom" :clearable="false" style="width:100%" @input="planMenuOrigineId = $event.id;checkValide()"></v-select>
          <div class="error" v-if="!$v.planMenuOrigineId.required">Ce champ est requis</div>
        </div>
        <div class="form-group">
          <v-date-picker class="date-picker" is-range :columns="2" :step="1" v-model="periodeOrigineSelectionnee" :popover="{ visibility: 'click' }" @input="changerPeriodeOrigine($event.start, $event.end)">
            <template v-slot="{ inputValue, inputEvents }">
              <input :value="inputValue.start || inputValue.end ? inputValue.start + ' au ' + inputValue.end : 'Sélectionnez une période'" v-on="inputEvents.start" />
            </template>
          </v-date-picker>
        </div>
        <h4 class="mt-2"><b>Destination</b></h4>
        <div class="form-group">
          <v-date-picker class="date-picker" :columns="1" :step="1" v-model="lundiDeLaSemaineDestination" :popover="{ visibility: 'click' }" @input="changerDebutDestination">
            <template v-slot="{ inputValue, inputEvents }">
              <input :value="inputValue ? inputValue + ' au ' + dimancheDeLaSemaineDestination : 'Choisir une date'" v-on="inputEvents" />
            </template>
          </v-date-picker>
          <div class="error" v-if="!$v.lundiDeLaSemaineDestination.required">Ce champ est requis</div>
        </div>

        <div class="card form-group">
          <div class="card-body pb-0">
            <div v-if="multiEtablissements">
              <b-button type="submit" class="btn btn-primary" title="Ajouter une destination" :disabled="!estValide" @click="ajouterDestination()">Ajouter une destination</b-button>
            </div>
            <div v-if="!multiEtablissements">
              <div class="form-group">
                <label for="selPlanMenuDestination">Structure de menu</label>
                <MultiSelect id="selPlanMenuDestination" :value="destinations[0].planMenuDestinationIds" :items="destinations[0].options || []" itemText="nom" itemValue="id" placeholder="Structures de menus..." emptyText="Toutes les structures ont été ajoutées" @change="changerPlansDestination(destinations[0], $event)" />
                <div class="error" v-if="!destinations[0].plansValides">Ce champ est requis</div>
              </div>
              <div v-if="menuSurPlanAlimentaire">
                <div class="form-group">
                  <label for="selPlanAlimDestination">Plan alimentaire de destination</label>
                  <v-select id="selPlanAlimDestination" placeholder="Plan alimentaire de destination..." v-model="destinations[0].planAlimentaireId" :options="optionsPlansAlimentaires" label='nom' :reduce="pa => pa.id"></v-select>
                </div>
                <div v-if="destinations[0].planAlimentaireId" class="form-group">
                  <a class="fas fa-exclamation-triangle"></a> Les menus seront dupliqués sur la période complète du plan alimentaire à partir de la date de destination
                </div>
              </div>
            </div>
            <div v-else>
              <div v-for="(destination, iDest) in destinations" :key="iDest">
                <hr v-if="iDest > 0" class="mt-0" />
                <div v-if="destinations.length > 1" class="mt-2 flex-end"><b-button class="btn btn-danger" title="Supprimer cette destination" @click="supprimerDestination(iDest)">&times;</b-button></div>
                <div class="form-group">
                  <label :for="'selEtabDestination' + iDest">Etablissement de destination</label>
                  <v-select :id="'selEtabDestination' + iDest" style="width:100%" :clearable="false" placeholder="Etablissement de destination..." v-model="destination.etablissementDestinationId" :options="optionsEtablissement" @input="changerEtablissementDestination(destination)"></v-select>
                  <div class="error" v-if="!destination.etabValide">Ce champ est requis</div>
                </div>
                <div class="form-group">
                  <label :for="'selPlanMenuDestination' + iDest">Structure(s) de menus</label>
                  <MultiSelect :id="'selPlanMenuDestination' + iDest" style="width:100%" :value="destination.planMenuDestinationIds" :items="destination.options || []" itemText="nom" itemValue="id" placeholder="Structures de menus..." emptyText="Toutes les structures ont été ajoutées" @change="changerPlansDestination(destination, $event)" />
                  <div class="error" v-if="!destination.plansValides">Ce champ est requis</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 push-top-1rem">
          <div class="row">
            <div class="col col-2">
              <ToggleButton name="ecraserMenuDestination" id="ecraserMenuDestination" v-model="ecraserMenuDestination" :key="ecraserMenuDestination" @input="hasMods = true" :height="21" :width="60" style="margin-bottom:0;margin-left:10px;" 
                        :labels="{checked: 'Oui', unchecked: 'Non'}" :color="{checked: '#ea0000de', unchecked: '#cccccc'}" v-tooltip="{ content: 'Attention, action irréversible qui supprimera tout menu présent sur la(les) destinations.'}" />
            </div>
            <div class="col col-10">
              <label for="ecraserMenuDestination"><i class="fas fa-exclamation-triangle"></i> &Eacute;craser le(s) menu(s) de destination présent(s).</label>
            </div>
          </div>
          <div class="row" v-if="this.multiEtablissements">
            <div class="col col-2">
              <ToggleButton name="ecraserRecetteDestination" id="ecraserRecetteDestination" v-model="ecraserRecetteDestination" :key="ecraserRecetteDestination" @input="hasMods = true" :height="21" :width="60" style="margin-bottom:0;margin-left:10px;" 
                        :labels="{checked: 'Oui', unchecked: 'Non'}" :color="{checked: '#ea0000de', unchecked: '#cccccc'}" v-tooltip="{ content: 'Attention, action irréversible qui remplacera toutes les recettes validées présentes sur la(les) destinations.'}" />
            </div>
            <div class="col col-10">
              <label for="ecraserRecetteDestination"><i class="fas fa-exclamation-triangle"></i> Prendre en compte les modifications de recettes.</label>
            </div>
          </div>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { connecteData } from "@/mixins/connecteData"
import { dupliquerMenu, chargerTousLesPlans } from '@/api/gpao/menus'
import { getLundiDeLaSemaine, getDimancheDeLaSemaine } from '@/mixins/index'
import { required } from "vuelidate/lib/validators"
import { showAlerts } from '@/mixins/alerts'
import { formatageDate, formatHumanDateAvecSlashes } from '@/mixins/dateFormats'
import MultiSelect from '@/components/Inputs/MultiSelect'
import { mapGetters } from "vuex"

export default {
  name: "ModalDuplicationMenu",
  props: ['menuPlanId', 'lundiDeLaSemaine', 'disabled', 'menuSurPlanAlimentaire'],
  mixins: [connecteData, getLundiDeLaSemaine, getDimancheDeLaSemaine, showAlerts, formatageDate, formatHumanDateAvecSlashes],
  components: {
    MultiSelect
  },
  data() {
    return {
      duplicationEnCours: false,
      estValide: false,
      tousLesPlansSource: [],
      tousLesPlansDestination: {},
      plansDeMenusAAfficher: [],
      afficherDuplication: false,
      planMenuOrigineId: this.menuPlanId,
      planMenuOrigineSelectionne: [],
      periodeOrigineSelectionnee: {},
      lundiDeLaSemaineOrigine: null,
      dimancheDeLaSemaineOrigine: null,
      optionsPlansAlimentaires: this.$store.state.definitions.plansAlimentaires,
      multiEtablissements: false,
      optionsEtablissement: [],
      destinations: [{
        planAlimentaireId: null,
        planMenuDestinationIds: null,
        etablissementDestinationId: null
      }],
      lundiDeLaSemaineDestination: this.lundiDeLaSemaine,
      key: 1,
      ecraserMenuDestination: false,
      ecraserRecetteDestination: false
    }
  },
  validations: {
    planMenuOrigineId: { required },
    lundiDeLaSemaineOrigine: { required },
    dimancheDeLaSemaineOrigine: { required },
    planMenuDestinationIds: { required },
    lundiDeLaSemaineDestination: { required }
  },
  computed: {
    ...mapGetters("user", ["utilisateurCourant"]),
    dimancheDeLaSemaineDestination() {
      if (!this.lundiDeLaSemaineOrigine || !this.dimancheDeLaSemaineOrigine || !this.lundiDeLaSemaineDestination)
        return null

      let ecartNbJours = (this.dimancheDeLaSemaineOrigine.getTime() - this.lundiDeLaSemaineOrigine.getTime()) / (1000 * 3600 * 24)

      let curr = new Date(this.lundiDeLaSemaineDestination.getTime());
      let first = curr.getDate()
      let last = first + ecartNbJours

      return this.formatHumanDateAvecSlashes(new Date(curr.setDate(last)))
    }
  },
  methods: {
    init() {
      this.plansDeMenusAAfficher = []
      this.planMenuOrigineId = this.menuPlanId
      this.lundiDeLaSemaineOrigine = this.lundiDeLaSemaine
      this.lundiDeLaSemaineDestination = this.getLundiDeLaSemaineApresAujourdhui(this.lundiDeLaSemaine, true)
      this.optionsEtablissement = this.utilisateurCourant.etablissements.map(e => {
       return {
          value: e.eId,
          label: e.eRS
        }
      })
      this.multiEtablissements = this.optionsEtablissement.length > 1
      if (!this.multiEtablissements) {
        this.destinations[0].planAlimentaireId = null
        this.destinations[0].etablissementDestinationId = {value: this.etabCourantId}
        this.changerEtablissementDestination(this.destinations[0])
      }
      chargerTousLesPlans(this.etabCourantId)
        .then(function (response) {
          this.tousLesPlansSource = response.data.sort((a, b) => a.nom.toLowerCase() > b.nom.toLowerCase() ? 1 : -1)
          this.planMenuOrigineSelectionne = this.tousLesPlansSource.find(pa => pa.id === this.menuPlanId)
          return
        }.bind(this))
        .catch((error) => {
          this.alerteErreur(error)
        })
      this.changerPeriodeOrigine(this.lundiDeLaSemaineOrigine, this.lundiDeLaSemaineOrigine)
      this.ecraserMenuDestination = false
      this.ecraserRecetteDestination = false
    },
    checkValide() {
      this.estValide = this.planMenuOrigineId
        && this.getLundiDeLaSemaineApresAujourdhui(this.lundiDeLaSemaineOrigine, false)
        && this.destinations.length
        && !this.destinations.filter(d => !d.etabValide || !d.plansValides).length
      this.key += 1
    },
    ajouterDestination() {
      this.destinations.unshift({
        planMenuDestinationIds: null,
        etablissementDestinationId: null
      })
      this.checkValide()
    },
    supprimerDestination(index) {
      this.destinations.splice(index, 1)
      this.checkValide()
    },
    changerEtablissementDestination(destination) {
      destination.plansValides = false
      destination.planMenuDestinationIds = []
      if (!destination.etablissementDestinationId.value) {
        destination.etabValide = false
        return
      }
      destination.etabValide = true
      return chargerTousLesPlans(destination.etablissementDestinationId.value)
        .then(response => {
          destination.options = response.data.sort((a, b) => a.nom.toLowerCase() > b.nom.toLowerCase() ? 1 : -1)
          this.checkValide()
          return
        })
    },
    changerPlansDestination(destination, e) {
      destination.planMenuDestinationIds = e
      destination.plansValides = destination.planMenuDestinationIds.length > 0
      this.checkValide()
    },
    getLundiDeLaSemaineApresAujourdhui(date, forcerApresAujourdhui) {
      if (!date)
        return null
      let dateRetour = this.getLundiDeLaSemaine(date)
      if (new Date(dateRetour) < new Date() && forcerApresAujourdhui)
        return null
      return this.getLundiDeLaSemaine(date)
    },
    async changerPeriodeOrigine(dateDebut, dateFin) {
      this.periodeOrigineSelectionnee.start = this.getLundiDeLaSemaine(dateDebut)
      this.periodeOrigineSelectionnee.end = this.getDimancheDeLaSemaine(dateFin)
      this.lundiDeLaSemaineOrigine = this.periodeOrigineSelectionnee.start
      this.dimancheDeLaSemaineOrigine = this.periodeOrigineSelectionnee.end
      this.checkValide()
    },
    changerDebutDestination(date) {
      this.lundiDeLaSemaineDestination = this.getLundiDeLaSemaine(date)
      this.checkValide()
    },
    show() {
      this.afficherDuplication = true
    },
    hide() {
      this.afficherDuplication = false
    },
    async dupliquer() {
      try {
        console.log("duplication")
        this.duplicationEnCours = true
        let params = []
        this.destinations.forEach(d => {
          params.push({
            structureMenuOrigineId: this.planMenuOrigineId,
            dateDebutOrigine: this.formatDateAvecTirets(this.lundiDeLaSemaineOrigine),
            dateFinOrigine: this.formatDateAvecTirets(this.dimancheDeLaSemaineOrigine),
            dateDestination: this.formatDateAvecTirets(this.lundiDeLaSemaineDestination),
            ecraserMenuDestination: this.ecraserMenuDestination,
            ecraserRecetteDestination: this.ecraserRecetteDestination,
            structureMenuDestinationIds: d.planMenuDestinationIds,
            etablissementDestinationId: d.etablissementDestinationId.value,
            planAlimentaireId: d.planAlimentaireId
          })
        })
        for (let i = 0; i < params.length; i++) {
          await dupliquerMenu(this.etabCourantId, params[i])
          this.$emit('menu-duplique', params[i])
        }
        
        this.alerteSucces("Menu dupliqué avec succès !")
      } finally {
        this.duplicationEnCours = false
        this.afficherDuplication = false
      }
    }
  }
}
</script>
