<template>
  <div>
    <ModalAjoutMvtStock id="modalAjoutMvtStock" ref="modalAjoutMvtStock" :disabled="inventaireOuvert" title="Ajouter en stock" :dateEtatAu="dateEtatAu" action="in" mode="stock" :datesInvalides="datesInvalidesMvtStock" @envoyer-article="enregistrerEnStock($event)" />
    <ModalAjoutMvtStock id="modalRetraitMvtStock" ref="modalRetraitMvtStock" :disabled="inventaireOuvert" title="Retirer du stock" :dateEtatAu="dateEtatAu" action="out" mode="stock" :datesInvalides="datesInvalidesMvtStock" @envoyer-article="retirerDuStock($event)" />
                                        
    <div class="container-fluid">
      <BarreFiltres :filtres="filtres" @appliquer-filtre="appliquerFiltre($event)" />
      <BarreActions :actions="actions" @appliquer-action="executerAction($event)" />

      <div class="card table-scroll height-responsive-100">
        <div class="card-header flex-center-space-between">
          <div class="flex-start">
            <span>
              {{ formatHumanDate(new Date(stock.date)) }}
              <button type="button" v-if="stock && stock.date && formatDateAvecTirets(new Date(stock.date)) !== formatDateAvecTirets(new Date())" class="btn btn-warning btn-sm ml-2" title="Afficher aujourd'hui" @click.stop="changerDateStock(new Date())">
                Afficher stock d'aujourd'hui
              </button>
            </span>
            <span v-if="inventaireOuvert" class="text-warning ml-3"><i class="fas fa-exclamation-triangle text-warning"></i> Inventaire en cours : toutes les modifications sont verrouillées</span>
          </div>
          <div class="flex-end">
            <span class="ml-5">Total HT : {{ stock.valeurTotale | afficherPrix2 }}</span>
            <span class="ml-5">Total TTC : {{ stock.valeurTotaleTTC | afficherPrix2 }}</span>
          </div>
        </div>
        <table class="table table-bordered table-sm text-center table-header-secondary">
          <colgroup>
            <col width="120">
            <col>
            <col width="120">
            <col width="120">
            <col width="150">
            <col width="120">
          </colgroup>
          <thead>
            <th>Référence</th>
            <th class="aligner-gauche">Désignation</th>
            <th>N° Lot</th>
            <th>DLC/DDM</th>
            <th>Quantité</th>
            <th>Total HT</th>
          </thead>
        </table>
        <div class="table-body">
          <div v-if="enCalculStock" style="text-align:center">
            <img alt="en attente" src="/assets/images/spinners/cooking-blue-court.gif" style='height:160px;' />
          </div>
          <table v-else class="table table-sm table-bordered text-center">
            <colgroup>
              <col width="120">
              <col>
              <col width="120">
              <col width="120">
              <col width="150">
              <col width="115">
            </colgroup>
            <tbody>
              <template v-for="(zone, i) in stock.zonesStockage">
                <tr class="table-header-tertiary" style="position:sticky;top:0;z-index:5" :key="'zone' + i">
                  <td colspan="6">
                    <div class="flex-center-space-between">
                      <span>{{zone.designation.toUpperCase()}}</span>
                      <span>{{zone.valeur | afficherPrix}}</span>
                    </div>
                  </td>
                </tr>
                <template v-for="(ingredient, index) in zone.ingredients.filter(i => afficherQuantitesAZero || (i.lots && i.lots.reduce((q, l) => q + l.quantite, 0) !== 0))">
                  <tr class="table-header-secondary" :key="'zone' + i + 'ing' + index">
                    <td colspan="6">
                      <div class="container-fluid">
                        <div class="row">
                          <div class="col-8 flex-start">
                            <BtnAffichageMvtsStock v-if="ingredient.id != 0" :id="ingredient.id" class="mr-1" :ingredient="ingredient" :debut="filtres.dateStock.selection" :avecDetails="true" />
                            <i v-else class="fas fa-exclamation-triangle error mr-2"></i>
                            <span v-if="ingredient.id ==0" class="error">{{ingredient.nom.toUpperCase()}}</span> 
                            <span v-else>{{ingredient.nom.toUpperCase()}}</span>
                          </div>
                          <div class="col-2 flex-end">
                            {{ingredient.quantite | afficherArrondi}} {{ingredient.uc}}
                          </div>
                          <div class="col-2 flex-end">
                            {{ingredient.valeur | afficherPrix}}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-for="(lot, indexLot) in ingredient.lots.filter(l => afficherQuantitesAZero || l.quantite !== 0)" :key="'zone' + i + 'ing' + index + 'lot' + indexLot">
                    <td style="vertical-align: middle" :style="lot.quantite == 0 ? 'color: lightgrey;font-style:italic' : ''">
                      {{lot.reference}}
                    </td>
                    <td class="aligner-gauche">
                      <BtnAffichageMvtsStock class="mr-1" :id="lot.id" :lot="lot" :debut="filtres.dateStock.selection" :avecDetails="true" />
                      <span :style="lot.quantite == 0 ? 'color: lightgrey;font-style:italic' : ''">{{lot.designationFournisseur}}</span>
                    </td>
                    <td style="vertical-align: middle" :style="lot.quantite == 0 ? 'color: lightgrey;font-style:italic' : ''">
                      {{lot.NumLot}}
                    </td>
                    <td style="vertical-align: middle" :style="lot.quantite == 0 ? 'color: lightgrey;font-style:italic' : ''">
                      <i v-if="dluoProcheOuDepassee(lot) || dlcProche(lot) || dlcDepassee(lot)" class="fas fa-exclamation-triangle" :style="dlcDepassee(lot) ? 'color:red' : 'color:orange'"
                        v-tooltip="{ content: (dluoProcheOuDepassee(lot) ? 'DDM proche ou dépassée : ' : dlcProche(lot) ? 'DLC proche : ' : dlcDepassee(lot) ? 'DLC dépassée : ' : '') + formatHumanDate(lot.dlcDluo), placement: 'bottom' }"></i>
                      {{formatHumanDate(lot.dlcDluo)}}
                    </td>
                    <td>
                      <div class="flex-center-space-between">
                        <button type="button" class="btn btn-primary btn-sm" :disabled="inventaireOuvert" title="Retirer une quantité" @click.stop="afficherRetraitMvtStock(lot)">
                          <i class="fas fa-minus"></i>
                        </button>
                        <span :style="lot.quantite == 0 ? 'color: lightgrey;font-style:italic' : ''">{{lot.quantite | afficherArrondi}} {{lot.uc}}</span>
                        <button type="button" class="btn btn-primary btn-sm" :disabled="inventaireOuvert" title="Ajouter une quantité" @click.stop="afficherAjoutMvtStock(lot)">
                          <i class="fas fa-plus"></i>
                        </button>
                      </div>
                    </td>
                    <td style="vertical-align: middle;text-align:right" :style="lot.quantite == 0 ? 'color: lightgrey;font-style:italic' : ''">
                      {{(lot.quantite * (lot.valeurUnitaire ? lot.valeurUnitaire : 0)) | afficherPrix}}
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarreFiltres from '@/components/Divers/BarreFiltres'
import BarreActions from '@/components/Divers/BarreActions'
import ModalAjoutMvtStock from '@/components/Stock/ModalAjoutMvtStock'
import BtnAffichageMvtsStock from '../../components/Stock/BtnAffichageMvtsStock.vue'
import { connecteData } from "@/mixins/connecteData"
import { closeModal } from "@/mixins/index"
import { showAlerts } from '@/mixins/alerts'
import { formatageDate } from '@/mixins/dateFormats'
import { listeInventaires, ajouterEnStock, obtenirStock, sortirDuStock, viderZoneStockage } from '@/api/gpao/stock'
import { exporterStock } from '@/api/gpao/documents'
import { constantesId } from "@/helpers/constantes"
import { lancerTelechargementDeResponse, rechercherMultiTexte } from "@/helpers/utils"
import { verifierInventaireOuvert } from "@/helpers/verifications"
import _ from 'lodash'

export default {
  name: "PageStock",
  mixins: [connecteData, closeModal, formatageDate, showAlerts],
  components: {
    BarreFiltres,
    BarreActions,
    ModalAjoutMvtStock,
    BtnAffichageMvtsStock
  },
  data() {
    return {
      stock: {},
      dateEtatAu: '',
      filtreDlcDluo: constantesId.filtreToutesDlc,
      optionsZonesDeStockage: [],
      filtreZonesDeStockage: [],
      filtreNomProduit: '',
      zonesDeStockageEnMemoire: [],
      dateEtatAuPourApi: '',
      dlcDluoPourApi: '',
      enCalculStock: false,
      datesInvalidesMvtStock: [],
      enExportation:false,
      afficherQuantitesAZero: false,
      inventaireOuvert: false,
      filtres: {
        dateStock: {
          label: "Stock en date du ...",
          champ: "datepicker",
          selection: null,
          initialiser(contexte) {
            contexte.filtres.dateStock.filtrer(contexte, new Date())
          },
          filtrer(contexte, date) {
            let filtre = contexte.filtres.dateStock
            if (date) {
              filtre.selection = date
            }
            if (filtre.selection) {
              contexte.setDateEtatAu(contexte.formatDateAvecTirets(contexte.filtres.dateStock.selection))
            }
          }
        },
        limiteDlc: {
          label: "Péremption proche",
          champ: "select",
          options: [
            {
              valeur: constantesId.filtreToutesDlc,
              texte: "Tous les articles"
            },
            {
              valeur: constantesId.filtreDlcProches,
              texte: "DLC Proches"
            },
            {
              valeur: constantesId.filtreDlcDepassees,
              texte: "DLC dépassées"
            }],
          selection: null,
          initialiser(contexte) {
            contexte.filtres.limiteDlc.selection = constantesId.filtreToutesDlc
          },
          filtrer(contexte) {
            let filtre = contexte.filtres.limiteDlc
            if (filtre.selection) {
              contexte.filtreDlcDluo = filtre.selection.valeur
              contexte.filtrer()
            }
          }
        },
        lignesAZero: {
          label: "Lignes à 0",
          champ: "checkbox",
          selection: false,
          initialiser(contexte) {
            contexte.filtres.lignesAZero.selection = contexte.afficherQuantitesAZero
          },
          filtrer(contexte) {
            contexte.afficherQuantitesAZero = contexte.filtres.lignesAZero.selection
          }
        },
        zonesStockage: {
          label: "Zones de stockage",
          champ: "select",
          type: "multi",
          options: [],
          selection: [],
          initialiser(contexte) {
            let filtre = contexte.filtres.zonesStockage
            filtre.options = contexte.optionsZonesDeStockage//.map(zs => new { valeur: zs, texte: zs })
            filtre.selection = contexte.filtreZonesDeStockage
          },
          filtrer(contexte) {
            contexte.filtreZonesDeStockage = contexte.filtres.zonesStockage.selection
            contexte.filtrer()
          }
        },
        nomLot: {
          label: "Recherche ingrédient",
          champ: "input",
          type: "text",
          selection: "",
          initialiser(contexte) {
            contexte.filtres.nomLot.selection = contexte.filtreNomProduit
          },
          filtrer(contexte) {
            contexte.filtreNomProduit = contexte.filtres.nomLot.selection
            contexte.filtrer()
          }
        },
        // Appeler "this.filtres.initialiser(this)" dans mounted() => génère le tableau de filtres utilisés pour afficher les filtres dans la barre de filtres
        barreGauche: [],
        barreDroite: [],
        initialiser(contexte) {
          contexte.reinitialiser()
          contexte.filtres.barreGauche = [ contexte.filtres.dateStock, contexte.filtres.zonesStockage, contexte.filtres.nomLot ]
          contexte.filtres.barreDroite = [ contexte.filtres.limiteDlc, contexte.filtres.lignesAZero ]
          contexte.filtres.barreGauche.forEach(f => {
            if (f.initialiser) {
              f.initialiser(contexte)
            }
          })
          contexte.filtres.barreDroite.forEach(f => {
            if (f.initialiser) {
              f.initialiser(contexte)
            }
          })
        }
      },
      actions: {
        // Boutons à afficher dans la barre d'action
        // Exemple :
        /*
        nomAction: {
          label: Label affiché sur le bouton (string),
          disabled: Etat actif/inactif par défaut du bouton (bool),
          class: Classe CSS (btn-primary, btn-success, btn-warning ou btn-danger) à appliquer sur le bouton (par défaut : "btn-primary" est appliqué => il s'agit du bouton bleu Agap'pro) (string)
          methode(contexte) {
            // => contexte équivaut à this (mais this ne fonctionne pas car l'initialisation de data n'est pas encore terminée, donc data n'est pas encore rattaché à this)
            traitements à exécuter lors du clic sur le bouton
            les traitements définis dans cette page doivent être appelés sous la forme : contexte.traitement()

            Remarque : pour que le système fonctionne, il faut déclarer la méthode ci-dessous dans la section "methods" de cette page
            executerAction(methode) {
              methode(this)
            }

          }
        }
        */
        ajouterMvtStock: {
          label: "Ajouter un article en stock",
          methode(contexte) {
            contexte.afficherAjoutMvtStock()
          }
        },
        exporter: {
          label: "Exporter le stock",
          methode(contexte) {
            contexte.exporter(contexte.stock.date)
          }
        },
        // Appeler "this.actions.initialiser(this)"" dans mounted() => génère les tableaux d'actions utilisés pour afficher les boutons à gauche et à droite de la barre d'actions
        barreGauche: [],
        barreDroite: [],
        initialiser(contexte) {
          contexte.actions.barreGauche = [ contexte.actions.ajouterMvtStock, contexte.actions.exporter ]
          contexte.actions.ajouterMvtStock.disabled = contexte.inventaireOuvert
        },
      }
    }
  },
  computed: {
    constantesId() {
      return constantesId
    }
  },
  methods: {
    appliquerFiltre(filtrer) {
      // Nécessaire pour faire fonctionner les filtres de la barre de filtres
      filtrer(this)
    },
    executerAction(methode) {
      // Nécessaire pour faire fonctionner les actions de la barre d'action
      methode(this)
    },
    reinitialiser() {
      this.filtreDlcDluo = constantesId.filtreToutesDlc
      this.filtreZonesDeStockage = []
      this.stock.zonesStockage = _.cloneDeep(this.zonesDeStockageEnMemoire)
    },
    filtrer() {
      this.enCalculStock = true
      setTimeout(() => { // affiche l'attente avant de continuer
        this.stock.zonesStockage = _.cloneDeep(this.zonesDeStockageEnMemoire)//application des filtres
        this.filtrerZones()
        this.filtrerNomProduit()
        this.filtrerDlcDluo()

        this.stock.zonesStockage.forEach(zone => {//suppression des zones vides
          _.remove(zone.ingredients, ing => !ing.lots.length)
        })
        _.remove(this.stock.zonesStockage, zone => !zone.ingredients.length)

        this.enCalculStock = false
      }, 1)
    },
    filtrerZones() {
      if (this.filtreZonesDeStockage.length) {
        _.remove(this.stock.zonesStockage, zone => !this.filtreZonesDeStockage.includes(zone.designation))
      }
    },
    filtrerNomProduit() {
      if (this.filtreNomProduit) {
        let zoneFiltre = []
        this.stock.zonesStockage.forEach(z => {
          z.ingredients.forEach(i => {
            let lotsFiltres = []
            i.lots.forEach(l => {
              if (rechercherMultiTexte(l.reference, this.filtreNomProduit) || rechercherMultiTexte(l.designationFournisseur, this.filtreNomProduit) || rechercherMultiTexte(l.NumLot, this.filtreNomProduit)) {
                lotsFiltres.push(l)
              }
            })
            if (lotsFiltres.length > 0) {
              i.lots = _.cloneDeep(lotsFiltres)
            }
            if (rechercherMultiTexte(i.nom, this.filtreNomProduit) || lotsFiltres.length > 0) {
              z.ingredients = []
              z.ingredients.push(i)              
              zoneFiltre.push(_.cloneDeep(z))
            }
          })
        })
        this.stock.zonesStockage = zoneFiltre.filter((val, ind, arr) => arr.indexOf(val) === ind)
      }
    },
    filtrerDlcDluo() {
      if (this.filtreDlcDluo === constantesId.filtreDlcProches) {
        this.stock.zonesStockage.forEach(zone =>
          zone.ingredients.forEach(ing =>
            ing.lots = ing.lots.filter(l => this.dlcProche(l))
          )
        )
      }

      if (this.filtreDlcDluo === constantesId.filtreDlcDepassees) {
        this.stock.zonesStockage.forEach(zone =>
          zone.ingredients.forEach(ing =>
            ing.lots = ing.lots.filter(l => this.dlcDepassee(l))
          )
        )
      }

      this.stock.zonesStockage.forEach(zone =>
        zone.ingredients = zone.ingredients.filter(i => i.lots?.length)
      )
      this.stock.zonesStockage = this.stock.zonesStockage.filter(z => z.ingredients?.length)
    },
    dluoProcheOuDepassee(lot) {
      if (lot.dlc || !lot.dlcDluo)
        return false
      let today = new Date(this.dateEtatAu) // Date choisie d'affichage du stock
      let dateLimite = this.formatDateAvecTirets(new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7))
      let dluoAvecTirets = this.formatDateAvecTirets(lot.dlcDluo)
      if (dluoAvecTirets <= dateLimite)
        return true
      return false
    },
    dlcProche(lot) {
      if (!lot.dlc || !lot.dlcDluo)
        return false
      let today = new Date(this.dateEtatAu) // Date choisie d'affichage du stock
      let dateDebut = this.formatDateAvecTirets(today)
      let dateFin = this.formatDateAvecTirets(new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7))
      let dlcAvecTirets = this.formatDateAvecTirets(lot.dlcDluo)
      if (dlcAvecTirets > dateDebut && dlcAvecTirets <= dateFin)
        return true
      return false
    },
    dlcDepassee(lot) {
      if (!lot.dlc || !lot.dlcDluo)
        return false
      let today = new Date(this.dateEtatAu) // Date choisie d'affichage du stock
      let dateLimite = this.formatDateAvecTirets(today)
      let dlcAvecTirets = this.formatDateAvecTirets(lot.dlcDluo)
      if (dlcAvecTirets <= dateLimite)
        return true
      return false
    },
    afficherRetraitMvtStock(lot) {
      this.$refs.modalRetraitMvtStock.show(lot)
    },
    async retirerDuStock(event) {
      await sortirDuStock(event.date, event.article)

      this.alerteSucces('Quantité retirée !')

      return obtenirStock(this.etabCourantId, this.dateEtatAuPourApi)
        .then((response) => {
          this.stock = response.data
          this.optionsZonesDeStockage = response.data.zonesStockage.map(zone => zone.designation)
          this.zonesDeStockageEnMemoire = _.cloneDeep(response.data.zonesStockage)
          this.filtrer()
          return
        })

    },
    changerDateStock(date) {
      this.filtres.dateStock.filtrer(this, date)
    },
    setDateEtatAu(event) {
      this.dateEtatAu = event
      this.dateEtatAuPourApi = this.formatDateAvecTirets(event)
      let date = null
      if (this.dateEtatAu === this.formatDateAvecTirets(new Date())) {
        date = this.formatDateAvecTirets(new Date())
      } else {
        date = this.dateEtatAuPourApi
      }
      this.enCalculStock = true
      return obtenirStock(this.etabCourantId, date)
        .then((response) => {
          this.stock = response.data
          this.optionsZonesDeStockage = response.data.zonesStockage.map(zone => zone.designation)
          this.zonesDeStockageEnMemoire = _.cloneDeep(response.data.zonesStockage)
          this.filtres.zonesStockage.initialiser(this)
          this.filtrer()
          return
        })
    },
    viderZoneStockage(event) {
      return viderZoneStockage(event.date, event.zoneStockage)
        .then(() => {
          this.alerteSucces('Zone de stockage vidée !')
          return obtenirStock(this.etabCourantId, event.date)
            
        }).then((response) => {
            this.stock = response.data
            this.optionsZonesDeStockage = response.data.zonesStockage.map(zone => zone.designation)
            this.zonesDeStockageEnMemoire = _.cloneDeep(response.data.zonesStockage)
            this.filtrer()
            return
          })
    },
    afficherAjoutMvtStock(lot) {
      this.$refs.modalAjoutMvtStock.show(lot)
    },
    enregistrerEnStock(event) {
      return ajouterEnStock(event.date, event.article)
        .then(() => {
          this.alerteSucces('Article ajouté !')
          this.changerDateStock(event.date)
          return
        })
    },
    async exporter(date) {
      try {
        this.enExportation = true
        const response = await exporterStock(this.etabCourantId, date)
        lancerTelechargementDeResponse(response)
      } finally {
        this.enExportation = false
      }
    },
    async verifierInventaire() {
      this.inventaireOuvert = await verifierInventaireOuvert(this.etabCourantId)
    }
  },
  async mounted() {
    await this.verifierInventaire()
    this.filtres.initialiser(this)
    this.actions.initialiser(this)
  },
  async created() {
    let response = await listeInventaires(this.etabCourantId)
    this.datesInvalidesMvtStock = response.data.map(el => this.formatDateAvecTirets(el.date))
  }
}
</script>

<style scoped>
.label-input {
  font-size: 1rem;
  color: #757575;
  margin-bottom: 0.5rem;
}
.push-top-item-header {
  margin-top: 1.9rem;
}
.refresh {
  font-size: 3rem;
  color: #757575;
}
</style>

<style>
label,
.v-input {
  font-size: 14px !important;
}
#datepicker-etat-au {
  width: 140px !important;
}
.v-input--selection-controls__input {
  margin-right: 0 !important;
}
</style>