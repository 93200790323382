<template >
  <div class="container-fluid">
    <BarreFiltres :filtres="filtres" @appliquer-filtre="appliquerFiltre($event)" />
    <div class="flex-center-center">
      <div class="card col-12 col-xl-10 col-xxl-8 height-responsive">
        <div class="card-header" >Stock - Documentation </div>
        <div class="card-container height-responsive-body height-responsive-1b">

          <section id="sectionStock">
            <div class="card-body">
              <p>Une fois qu'une commande est réceptionnée, vous avez la possibilité de transférer les produits dans votre stock à partir du menu "Commandes".</p>
              <p>Vous pouvez également ajouter des produits manuellement directement depuis le menu "Stock".</p>
            </div>
          </section>
          <section id="sectionInfoStock">
            <div class="card-body">
              <p>Les produits en stock sont affichés par zone de stockage puis par ingrédient.</p>
              <p></p>
            </div>
          </section>
          <section id="sectionAjouterStock">
            <div class="header header-tertiary">Ajouter du stock</div>
            <div class="card-body">
              <div class="container mb-3">
                <img class="border-black border-rounded w-100" src="../../assets/images/documentation/stock/stock/ajouterEnStock.png" alt="Illustration fenêtre ajouter en stock" >
              </div>
              <p>Pour ce faire, vous avez deux options de recherche :</p>
              <ul class="puces">
                <li>Par ingrédient.</li>
                <li>Par référence.</li>
              </ul>
              <div class="container mb-3">
                <img class="border-black border-rounded w-100" src="../../assets/images/documentation/stock/stock/ajoutStockExemple.png" alt="Illustration fenêtre ajouter en stock exemple">
              </div>
              <p>Pour sélectionner un produit, vous devez cliquer sur l'icône verte en bout de ligne : <i class="fas fa-circle cursor-pointer" style="color: green;"></i></p>
              <p>Cela ouvre les informations sur l'article, deux champs sont obligatoires pour l'enregistrer:</p>
              <ul class="puces">
                <li>Indiquer si c'est une entrée de marchandise ou une régularisation.</li>
                <li>La quantité.</li>
              </ul>
              <div class="container mb-3">
                <table class="table table-bordered text-center">
                  <thead class="table-header">
                    <tr>
                      <th>Boutons</th>
                      <th class="aligner-gauche">Signification</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><button title="Fiche technique" type="button" class="btn btn btn-primary btn-secondary"><i class="fas fa-book-open"></i></button></td>
                      <td class="aligner-gauche">Permet d'accéder à la fiche technique.</td>
                    </tr>
                    <tr>
                      <td><i class="fas fa-info-circle cursor-pointer"></i></td>
                      <td class="aligner-gauche">Permet d'accéder aux mouvements du produit.</td>
                    </tr>
                    <tr>
                      <td><button  type="button" title="Retirer une quantité" class="btn btn-primary"><i class="fas fa-minus"></i></button></td>
                      <td class="aligner-gauche">Dans la colonne quantité,  permet <a href="#sectionModificationStock">d'enlever du stock</a> pour un produit.</td>
                    </tr>
                    <tr>
                      <td><button  type="button" title="Ajouter une quantité" class="btn btn-primary"><i  class="fas fa-plus"></i></button></td>
                      <td class="aligner-gauche">Dans la colonne quantité, permet d'ajouter du stock pour un produit.</td>
                    </tr>
                    <tr>
                      <td class="warning"><i class="fas fa-exclamation-triangle mr-2"></i>PRODUIT SANS INGRÉDIENT</td>
                      <td class="aligner-gauche">Indique que les produits listés n'ont pas d'ingrédient rattaché. il faut affecter un ingrédient au produit hors marché à partir du menu Commandes -> Produits hors marché</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </section>

          <section id="sectionModificationStock">
            <div class="header header-tertiary">Modifier du stock</div>
            <div class="card-body">
              <p>Si vous souhaitez modifier la quantité d'un produit, vous devez sélectionner les boutons en fin de ligne du produit concerné.<button type="button" title="Retirer une quantité" class="btn btn-primary btn-sm"><i class="fas fa-minus"></i></button></p>
              <p>Le nom du produit en question s'affiche en en-tête de la fenêtre.</p>
              <div class="container mb-3">
                <img class="border-black border-rounded w-100" src="../../assets/images/documentation/stock/stock/stockRetireQuantite.png"   alt="Illustration fenêtre modifier la quantité d'un produit">
              </div>
              <p>Une fois modifié et enregistré, cela met à jour le total du stock.</p>
            </div>            
          </section>

          <section id="sectionStockAnterieur">
            <div class="header header-tertiary">Consulter les stocks antérieurs</div>
            <div class="card-body">
              <p>Sur le calendrier, vous avez la possibilité de consulter les stocks antérieurs.</p>
              <p>Un bouton jaune se présente en haut à gauche pour indiquer que vous ne consultez pas le stock du jour actuel. Il vous offre la possibilité de retourner vers le stock en cours.</p>
              <div class="flex-center-center mb-3">
                <div class="col-6">
                  <img class="border-black border-rounded w-100" src="../../assets/images/documentation/stock/stock/stockBtnJaune.png"   alt="Illustration bouton stock à jour">
                </div>
              </div>
            </div>
          </section>

          <section id="sectionInventaire">
            <div class="header header-tertiary">Inventaire</div>
            <div class="card-body">
              <p>Vous avez la capacité de procéder à un inventaire, ce processus inclut les données du stock.</p>
              <p>Au cours de cet inventaire, vous avez la possiblité de :</p>
              <ul class="puces">
                <li>Ajuster les quantités des produits existants.</li>
                <li>Intégrer de nouveaux produits.</li>
              </ul>
              <p>Pour consulter <a href="#sectionMouvements">les mouvements</a> des produits, vous pouvez utiliser l'icône "i".</p>
              <div class="h6 important">
                <p>Pendant la réalisation d'un inventaire, toutes les modifications relatives au stock et à la réception des commandes sont verrouillées.</p>
              </div>
            </div>
          </section>

          <section id="sectionMouvements">
            <div class="header header-tertiary">Mouvements</div>
            <div class="card-body">
              <p>Vous pouvez effectuer une recherche par ingrédient.</p>
              <p> Exemple: du lait 1/2 Ecreme, tous les mouvements de cet ingrédient vont s'afficher, vous pouvez ensuite filtrer par date.</p>
               <div class="flex-center-center mb-3">
                <div class="col-6">
                  <img class="border-black border-rounded w-100" src="../../assets/images/documentation/stock/stock/pageMouvements.png"   alt="Illustration bouton stock à jour">
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarreFiltres from '@/components/Divers/BarreFiltres'

export default {
  name: "DocStock",
  components: {
    BarreFiltres
  },
  data() {
    return {
      filtres: {
        sections: {
          label: "Choix de votre recherche...",
          champ: "select",
          options: [
            { valeur: 'sectionStock', texte: 'Stock'},
            { valeur: 'sectionAjouterStock', texte: 'Stock: Ajouter du Stock'},
            { valeur: 'sectionModificationStock', texte:'Stock: Modifier du stock' },
            { valeur: 'sectionStockAnterieur', texte: 'Stock: Consulter les stocks antérieurs' },
            { valeur: 'sectionInventaire',texte:'Inventaire'},
            { valeur: 'sectionMouvements',texte:'Mouvements'}
            
          ],
          selection: [],
          filtrer(contexte) {
            let filtre = contexte.filtres.sections
            if (filtre.selection) {
              let sectionSelectionnee = document.getElementById(filtre.selection.valeur);
              if (sectionSelectionnee) {
                sectionSelectionnee.scrollIntoView();
              }  
            }           
          }
        },
        // Appeler "this.filtres.initialiser(this)" dans mounted() => génère le tableau de filtres utilisés pour afficher les filtres dans la barre de filtres
        barreGauche: [],
        barreDroite: [],
        initialiser(contexte) {
          contexte.filtres.barreGauche = [ contexte.filtres.sections ]
        }
      },
    }
  }, 
  methods: {
    appliquerFiltre(filtrer) {
      // Nécessaire pour faire fonctionner les filtres de la barre de filtres
      filtrer(this)
    },
  },
  mounted() {
    this.filtres.initialiser(this)
  }
}
</script>
