<template>
<div class="container-fluid">  
  <BarreFiltres :filtres="filtres" @appliquer-filtre="appliquerFiltre($event)" />
  <div class="flex-center-center">
    <div class="card col-12 col-xl-10 col-xxl-8 height-responsive">
      <div class="card-header" >
        Commandes - Documentation
      </div>
      <div class="card-container height-responsive-body height-responsive-1b">
        <div class="card-body">
          <section id="sectionCommandes">
            <p>Sur cette page, vous avez la possibilité d'accéder à la liste de vos commandes</p>  
            <p>Vous avez la possibilité de créer une nouvelle commande en cliquant sur "Créer une nouvelle commande". </p>
            <div class="container mb-3">
              <img class="border-black border-rounded w-100" src="../../assets/images/documentation/commandes/suivi/creerCommande.png" alt="Image création commande">
            </div>
            <p>Après l'ouverture de la fenêtre, vous pourrez nommer votre commande et spécifier la période désirée. Ensuite, deux options s'offrent à vous :</p>
            <ul class="puces">
              <li>Vous avez la possibilité d'entrer des<a href="#sectionCommandesManuelle"> commandes vierges.</a></li>
              <li>Vous pouvez également utiliser des<a href="#sectionCommandesPrecalculees"> commandes précalculées </a>à partir des menus</li>              
            </ul>
          </section>
        </div>

        <section id="sectionCommandesManuelle">
          <div class="header header-tertiary">Commande vierge</div>
          <div class="card-body">                 
            <div class="container mb-3">
              <img class="border-black border-rounded w-100" src="../../assets/images/documentation/commandes/suivi/commandeManuelSuivi.png" alt="Image commandes Manuel">
            </div>      
            <p><span class="bold600">Section 1</span>: dans la barre du haut, de gauche à droite, vous trouverez les éléments suivants :</p>
            <div class="container mb-3">
              <img class="border-black border-rounded w-100" src="../../assets/images/documentation/commandes/suivi/commandeSection1.png" alt="Image commandes section 1">
            </div> 
            <ul class="puces">
              <li>Choisir produit : Pour accéder à la <a href="#sectionMercuriale">Mercuriale.</a></li>
              <li>Optimiser prix: Permet de trouver le prix le plus intérressant sur toutes les lignes de la commande.</li>
              <li>Optimiser fournisseurs: Permet de regrouper la commande sur le moins de fournisseurs possible.</li>
              <li>Choisir fournisseur: Permet de basculer le plus de produit possible sur un fournisseur selectionné.</li>
              <li>Exporter: Permet de récupérer de manière brute les lignes de commandes au format Excel.</li>
              <li>Bon de commande: Permet de <a href="#sectionPasserCommande">passer votre commande</a> par fournisseur ou d'envoyer votre commande à tous les fournisseurs en une seule action </li>
              <li><button class="btn btn-primary btn-sm"><i class="fas fa-caret-up"></i></button>&nbsp;<button class="btn btn-primary btn-sm"><i class="fas fa-caret-down"></i></button> Permet de trier les lignes de la commande, remonte ou descends la ligne dans la liste. Ce tri est maintenu à l'enregistrement de la commande</li>
              <li>Retour: Vous ramène à la liste de vos commandes.</li>
              <li>Annuler: Annule vos dernières modifications</li>
              <li>Enregistrer: Enregistre votre commande</li>             
            </ul>
            <p><span class="bold600">Section 2</span>: Ligne produits de votre commande</p>
            <div class="container">
              <table class="table table-bordered text-center">
                <thead class="table-header">
                  <th>Légende</th>
                  <th class="aligner-gauche">Signification</th>
                </thead>
                <tbody>
                  <tr>
                    <td><span class="table ligne-modifiee">Police en gras et italique</span></td>
                    <td class="aligner-gauche">Produit modifié.</td>
                  </tr>
                  <tr>
                    <td><span class="table ligne-desactivee">Police en gris et italique</span></td>
                    <td class="aligner-gauche">La commande de ce produit a été réalisée.</td>
                  </tr>
                  <tr>
                    <td class="warning"><em><i class="fas fa-exclamation-triangle ligne-modifiee"></i> Produit sans ingrédient</em></td>
                    <td class="aligner-gauche">Indique qu'un produit hors marché n'est rattaché à aucun ingrédient. Il faut associer un ingrédient au produit à partir de la page "Produits hors marché" afin de profiter des comparaisons de prix lors des substitutions de fournisseurs et de la sélection automatique dans les commandes depuis les recettes.</td>
                  </tr>
                  <tr>
                    <td class="bg-error">Ingrédient indisponible avec fond rouge</td>
                    <td class="aligner-gauche">Indique qu'un produit est indisponible.</td>
                  </tr>
                  <tr>
                    <td class="bg-error">Case fournisseur en rouge</td>
                    <td class="aligner-gauche">Signifie qu'un produit a été sélectionné par la désignation, mais aucune référence fournisseur n'a été choisie.</td>
                  </tr>
                   <tr>
                    <td class="bg-warning">Case Qté. PCB en jaune</td>
                    <td class="aligner-gauche">Signifie que la quantité demandée et diffrente de la quantité PCB </td>
                  </tr>
                  <tr>
                    <td><button type="button" class="btn btn-primary"><i class="fas fa-exchange-alt"></i></button></td>
                    <td class="aligner-gauche"><a href="#sectionSubstitution">Substitution de produit.</a> Permet également d'accéder aux fiches techniques et d'ajouter des favoris. Cliquer sur le point vert en bout de ligne, pour changer de produit.</td>
                  </tr>
                  <tr>
                    <td><button type="button" class="btn btn-danger "><i class="fas fa-trash"></i></button></td>
                    <td class="aligner-gauche">Suppression du produit.</td>
                  </tr>                  
                </tbody>
              </table>
              <div class="container mb-3">
                <img class="border-black border-rounded w-100" src="../../assets/images/documentation/commandes/suivi/commandeSection2.png" alt="Image commandes Manuel">
              </div>
              <p>Dans cette section, vous retrouvez plusieurs colonnes : </p>
              <ul class="puces">
                <li>Fam: La famille du produit</li>
                <li>Désignation: Description du produit selectionné</li>
                <li>Fournisseurs: Liste des différents fournisseurs</li>
                <li>Réf.: Référence des produits</li>
                <li>Marque: Marque des produits</li>
                <li>Qté. stock: La quantité du produit déja présent dans votre stock</li>
                <li>Qté. demandée: La quantité que vous avez selectionnée</li>
                <li>Qté. PCB (PCB): La quantité qui va être commandée.</li>
                <li>PU HT: Prix Unitaire Hors Taxe</li>
                <li>Total HT: Total du PU HT x Qté. PCB (PCB)</li>
              </ul>
            </div>
            <p><span class="bold600">Section 3</span>: Details fournisseurs</p>
            <div class="container mb-3">
                <img class="border-black border-rounded w-100" src="../../assets/images/documentation/commandes/suivi/commandeSection3.png" alt="Image commandes Manuel">
              </div>
            <p>Cette section est équipée d'un filtre qui facilite le tri des produits par fournisseur. Une fois le filtre activé, il vous suffit de choisir un fournisseur dans la liste pour afficher les produits correspondants.</p>
            <ul class="puces">
              <li>Fournisseur : Indique les fournisseurs que vous avez sélectionnés pour votre commande.</li>
              <li>Lignes: Correspond à la quantité de produits commandés par fournisseur.</li>
              <li>Bon de commande: Le numéro du bon de commande est généré automatiquement. Si vous souhaitez le modifier, vous pouvez le faire en cliquant dessus.</li>
              <li>Livraison: Si le champ est laissé vide, la commande ne peut pas être effectuée. En cliquant dessus, un calendrier s'ouvre (voir l'image ci-dessous), les jours marqués en vert sont ceux préalablement paramétrés dans le menu fournisseurs. Par défaut, la date sélectionnée sera la plus proche de la création de la recette.</li>
              <li>Franco:  Si le montant de franco n'est pas atteint, il sera affiché avec un <span class="bg-warning p-1 border-rounded"> fond jaune</span></li>
              <li>Total HT: indique le montant total de la commande par fournisseur</li>
            </ul>
            <p>Livraison:</p>
            <div class="flex-center-center mb-3">
              <div class="col-6">
                <img class="border-black border-rounded w-100" src="../../assets/images/documentation/commandes/suivi/commandeLivraison.png" alt="Image livraison">
              </div> 
            </div>   
          <div class="container">
              <table class="table table-bordered text-center">
                <thead class="table-header">
                  <th>Légende</th>
                  <th class="aligner-gauche">Signification</th>
                </thead>
                <tbody>                  
                  <tr>
                    <td><button type="button" class="btn btn-primary"><i class="fas fa-envelope"></i></button></td>
                    <td class="aligner-gauche">Envoyer le bon de commande par mail.</td>
                  </tr>
                    <tr>
                    <td><button type="button" class="btn btn-primary"><i class="fas fa-phone"></i></button></td>
                    <td class="aligner-gauche">Cette fonctionnalité est à utiliser lorsque vous avez passé votre commande par téléphone ou que vous avez envoyé le bon de commande manuellement.</td>
                  </tr>
                  <tr>
                    <td><button type="button" class="btn btn-primary"><i class="fas fa-dolly-flatbed"></i></button></td>
                    <td class="aligner-gauche">Cette fonctionnalité est à utiliser lors de la<a href="#sectionReception"> réception de votre commande</a>.</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>

         <section id="sectionSubstitution">
          <div class="header header-tertiary">Substitution de produit</div>
          <div class="card-body">
            <p>Après voir cliqué sur le bouton de substitution de produit <button title="Substitution de produit" type="button" class="btn btn-primary btn-sm"><i class="fas fa-exchange-alt"></i></button>, cela vous ouvre la fenêtre ci-dessous.</p>
            <p>Sur celle-ci, vous trouverez des produits similaires. Vous pouvez consulter la fiche technique de chaque produit en utilisant : <button title="Fiche technique" type="button" class="btn btn btn-primary btn-secondary btn-sm"><i class="fas fa-book-open"></i></button></p>
            <p>Vous avez la posibilité d'utiliser l'étoile <i class="fa-star far" ></i> pour ajouter un produit dans vos favoris.</p>
            <p>Pour confirmer la substitution d'un produit, il vous suffit de cliquer sur le point vert <i class="fas fa-circle cursor-pointer" style="color: green;"></i> en bout de ligne.</p>
            <div class="container mb-3">
              <img class="border-black border-rounded w-100" src="../../assets/images/documentation/commandes/suivi/commandeSubstitution.png" alt="Image substitution de produit">
            </div>
          </div>
         </section>     

        <section id="sectionCommandesPrecalculees">
          <div class="header header-tertiary">Commande précalculées</div>
          <div class="card-body"> 
            <div class="container mb-3">
              <img class="border-black border-rounded w-100" src="../../assets/images/documentation/commandes/suivi/commandesSuivi.png" alt="Image commandes Suivi">
            </div>
            <p><span class="bold600">Dans la section numéro 1,</span> vous trouverez l'ensemble des produits, et c'est à ce niveau que vous pouvez modifier un produit ou le supprimer.</p>
            <p><span class="bold600">Dans la section numéro 2,</span> si vous sélectionnez un produit dans l'encadré numéro 1, vous obtiendrez des informations sur les recettes qui utilisent ce produit, y compris la quantité et la date prévue d'utilisation.</p>
            <p><span class="bold600">Dans la section numéro 3,</span> vous trouverez la liste des fournisseurs sélectionnés pour cette commande. En cliquant sur la date de livraison, vous pouvez choisir une nouvelle date. Par défaut, la date choisie est la plus proche de la réalisation de la recette. 
            Vous pouvez visualiser les produits par fournisseur en utilisant le filtre "Filtrer selon le fournisseur sélectionné".</p>
          </div>
        </section>
                              
        <section id="sectionMercuriale">
          <div class="header header-tertiary">Mercuriale</div>
          <div class="card-body">
            <p>La page mercuriale vous permet de retrouver tous les produits négociés par notre service achats.</p>
            <p><span class="bold600">Section 1</span>: dans la barre du haut, de gauche à droite, vous trouverez les éléments suivants :</p>
            <ul class="puces">
              <li>Recherche toutes familles</li>
              <li>Les différentes familles.</li>
              <li>Les produits de la mercuriale ou les produits hors marché.</li>
              <li>Une barre de recherche qui s'effectue dans la famille sélectionnée. Vous avez la possibilité de naviguer entre les différentes familles tout en maintenant votre recherche.</li>
              <li><a href="#sectionFavoris">Favoris</a></li>
            </ul>
            <div class="container mb-3">
              <img class="border-black border-rounded w-100" src="../../assets/images/documentation/commandes/mercuriale/mercuriale.png"  alt="Image Mercuriale" >
            </div>
            <p><span class="bold600">Section 2</span>: une fois votre recherche effectuée, plusieurs colonnes apparaissent :</p>
              <ul class="puces">
              <li>New indique qu'il s'agit d'un nouveau produit dans la mercuriale.</li>
              <li>Designation liste les différents résultats suite à votre recherche.</li>
              <li>UC représente l'Unité de Cotation</li>
              <li>Vous trouverez ensuite les tarifs pour les différents fournisseurs ainsi que les références.</li>
            </ul>
            <p><span class="bold600"> Section 3</span>: en cliquant sur la référence d'un produit, vous pouvez obtenir plus d'informations à son sujet.</p>
            <div class="container">
              <div class="flex-center-center mb-3">
                <div class="col-4">
                  <img class="border-black border-rounded w-100" src="../../assets/images/documentation/commandes/mercuriale/mercurialeSection3.png" alt="Image mercuriale section 3">
                </div>
              </div> 
              <table class="table table-bordered text-center">
                <thead class="table-header">
                  <tr>
                    <th>Bouton</th>
                    <th class="aligner-gauche">Signification</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><button title="Fiche technique" type="button" class="btn btn btn-primary btn-secondary "><i class="fas fa-book-open"></i></button></td>
                    <td class="aligner-gauche">Permet d'accéder à la fiche technique.</td>
                  </tr>
                  <tr>
                    <td><i class="fas fa-star text-primary"></i></td>
                    <td class="aligner-gauche">Produit en favoris.</td>
                  </tr>
                  <tr>
                    <td class="text-warning">Vendu par</td>
                    <td class="aligner-gauche">	Signifie que la quantité demandée et diffrente de la quantité PCB.</td>
                  </tr>
                </tbody>
              </table>
              <p>Exemple: Pour ma recette, j'ai besoin de 0.48 litre d'huile de tournesol. Cependant, le PCB (par combien) du fournisseur est de 5L. Votre commande sera effectuée avec le PCB du fournisseur.</p>
            </div>
            <p><span class="bold600">Section 4</span>: affiche la liste des produits qui seront ajoutés à votre commande une fois que vous aurez appuyé sur le bouton "Ajouter les lignes".</p>
            <div class="h6 important">
              <p>Pour retirer un produit de la liste d'ajout, vous disposez de deux options : </p>
              <ul class="puces">
                <li>Soit en indiquant une quantité de 0, ce qui le maintiendra dans la liste tout en le barrant. Lorsque vous enregistrez votre commande, la ligne correspondante sera supprimée.</li>
                <li>Soit en désélectionnant la ligne. Pour cela, positionnez-vous sur le produit dans la liste, section 4 et cliquez sur la référence en bleu, section 2.</li>                            
              </ul>
            </div>
          </div>
        </section>

        <section id="sectionFavoris">
          <div class="header header-tertiary">Favoris</div>
          <div class="card-body">
              <div class="container mb-3">
                <img class="border-black border-rounded w-100" src="../../assets/images/documentation/commandes/suivi/favorisMercuriale.png" alt="Image bon de commande">
              </div>
            <p>Vous avez la possibilité de mettre en favoris l'ensemble de la ligne avec toutes ses références ou de sélectionner une référence spécifique à marquer comme favori. Dans l'exemple ci-dessus, l'icône "Favoris" est cochée pour le Steak Végétal Soja Nature IQF 100g, ce qui signifie que toutes les références de cette ligne sont en favoris. Lorsque vous créez vos recettes, la référence la mieux placée sera mise en avant.</p>
            <p>Cependant, dans l'exemple où seule la référence 245234 est marquée comme favori, lors de la création de vos recettes, seule cette référence sera mise en avant, sans comparaison de prix avec d'autres références.</p>
            <p>Vous pouvez retrouver, par famille, tous vos favoris en cochant la case en haut à droite "Favoris"</p>
          </div>
        </section>

        <section id="sectionPasserCommande">
          <div class="header header-tertiary">Passer une commande</div>
          <div class="card-body">
            <p>Une fois que vous avez finalisé votre choix de produits, vous pouvez passer commande auprès des fournisseurs de deux manières :</p>
            <ul class="puces">
              <li><span class="bold600">Par téléphone ou autre</span> : Dans ce cas, vous devrez vous rendre dans la section "Commandes fournisseurs à envoyer" et sélectionner l'icône du téléphone en fin de ligne pour indiquer que la commande a été passée.</li>
              <li><span class="bold600">Par mail depuis Mercudyn</span> : Vous avez la possibilité d'envoyer un e-mail directement depuis Mercudyn. Pour ce faire, vous devrez d'abord renseigner votre profil avec votre adresse e-mail ainsi que celles des fournisseurs. Vous pouvez trouver ces étapes dans la section fournisseurs.</li>
            </ul>
            <div class="flex-center-center mb-3">
              <div class="col-8">
                <img class="border-black border-rounded w-100" src="../../assets/images/documentation/commandes/suivi/bonDeCommande.png" alt="Image bon de commande">
              </div>
            </div>
            <p>Si vous utilisez la fonction de messagerie, vous avez la possibilité d'imprimer et d'envoyer votre commande à tous les fournisseurs en une seule action. Cependant, si vous ne souhaitez pas imprimer, vous pouvez décocher la case "IMPRESSION".</p>
            <div class="h6 important">
              <p> Vous recevrez une copie de chaque e-mail envoyé.</p>
              <p>En cas d'erreur de réception de l'e-mail par un fournisseur, vous serez averti par e-mail, et la commande sera marquée en rouge sur Mercudyn. Nous vous recommandons de contacter le fournisseur par téléphone pour vérifier son adresse e-mail et finaliser la commande.</p>
            </div>
          </div>
        </section>

        <section id="sectionReception">
          <div class="header header-tertiary">Réception de commande</div>
          <div class="card-body">
            <p>Après avoir cliqué sur le bouton "Réceptionner la commande", la page ci-dessous s'affiche :</p>
            <div class="card-body"> 
              <div class="container mb-3">
                <img class="border-black border-rounded w-100" src="../../assets/images/documentation/commandes/suivi/commandeReception.png" alt="Image commandes Suivi">
              </div>            
            </div>
            <p>Dans la barre du haut, de gauche à droite, vous trouverez les éléments suivants :</p>
            <ul class="puces">
              <li>Ajouter un article: Si vous ajoutez un produit directement par téléphone après avoir validé la commande, vous avez la possibilité de l'ajouter lors de la réception de la commande.</li>
              <li>Déclarer un incident: Ce bouton sert a déclater un incident sur la totalité de la commande. Vous pouvez également déclarer un incident par ligne produit en utilisant le triangle avec le point d'exclamation.</li>
              <li>Ajouter un lot: Lors de la création d'un lot, il faut que la somme des quantités soit égale à la quantité livrée.Ensuite, vous avez la possibilité d'indiquer les numéros de lot et les dates limites de consommation (DLC).</li>
              <li>Annuler: Annule le contrôle à réception, retourne vers la page de commande.</li>
              <li>Enregistrer en stock: Enregistre les produits en stock.</li>
            </ul>
          </div>
        </section>

      <section id="sectionSpeciaux">
          <div class="header header-tertiary">Spéciaux</div>
          <div class="card-body">
            <div class="card-body"> 
              <div class="container mb-3">
                <img class="border-black border-rounded w-100" src="../../assets/images/documentation/commandes/speciaux/pageSpeciaux.png" alt="Image liste produits Hors Marché">
              </div>            
            </div>
            <p>Sur la page des spéciaux, vous trouverez tous les documents déposés par les fournisseurs et les industriels</p>
            <p>Les fournisseurs ou industriels en vert sont ceux qui ont déposé de nouveaux documents.</p>
            <p>Vous avez la possibilité d'utiliser différents filtres :</p>
            <ul class="puces">
              <li>1 . Le premier filtre vous sert à classer les documents par les fournisseurs qui proposent des documents alimentaires ou non alimentaires.</li>
              <li>2 . Le second vous propose de filtrer par famille.</li>
              <li>3 . Vous avez la possibilité d'effectuer une recherche de document dans la section 3.</li>
            </ul>
          </div>
        </section>

        <section id="sectionProduitsHM">
          <div class="header header-tertiary">Produits Hors Marché</div>
          <div class="card-body">
            <p>Vous pouvez ajouter des produits provenant de vos fournisseurs locaux, ou des produits non référencés de la mercuriale sur les fournisseurs connus.
               La liste de vos produits est disponible sur cet écran : 
            </p>
            <div class="card-body"> 
              <div class="container mb-3">
                <img class="border-black border-rounded w-100" src="../../assets/images/documentation/commandes/produitsHM/listeProduitsHM.png" alt="Image liste produits Hors Marché">
              </div>            
            </div>
            <p>Dans la barre du haut, de gauche à droite, vous trouverez les éléments suivants :</p>
            <ul class="puces">
              <li><a href="#sectionCreationProduitsHM">Ajouter un produit:</a> Permet de créer un produit hors marché, avec le tarif d'un fournisseur</li>
              <li>Dupliquer le produit: Permet de dupliquer un produit hors marché, afin de créer un nouveau tarif fournisseur par exemple</li>
              <li><a href="#sectionCreationProduitsHM">Modifier:</a> Modification du produit sélectionné.</li>
              <li>Supprimer: Suppression du produit sélectionné.</li>
              <li>Enregistrer: Ce bouton apparait si vous avez des produits sans ingrédient rattaché.</li>
            </ul>
            <p>A partir de cette liste, si certains produits n'ont pas d'ingrédient associé, l'indication <em class="warning"><i class="fas fa-exclamation-triangle ligne-modifiee warning"></i> Produit sans ingrédient</em> est visible dans la désignation. 
               Vous pouvez les modifier directement, en cherchant l'ingrédient depuis la zone de recherche, et enregistrer les produits modifiés via le bouton "Enregistrer"</p>
            <p>Cette liste peut etre triée lorsque l'on clique sur l'entête du tableau.</p>
            <p>Cette liste peut etre filtrée sur une ou plusieurs familles, à partir d'une recherche libre, ou uniquement les produits en erreur. Les produits en erreur correspondent aux articles qui n'ont pas d'ingrédient ou aucun allergène de renseigné.</p>
          </div>
        </section>

        <section id="sectionCreationProduitsHM">
          <div class="header header-tertiary">Création/Modification d'un produit hors marché</div>
          <div class="card-body">
            <p>La création, modification ou duplication d'un produit hors marché se fait à partir de la fenêtre ci-dessous:
            </p>
            <div class="card-body"> 
              <div class="container flex-center-center mb-3">
                <img class="border-black border-rounded w-50" src="../../assets/images/documentation/commandes/produitsHM/creationProduitsHM.png" alt="Image création ou modification d'un produit hors marché">
              </div>            
            </div>
            <p>Points importants sur la création d'un produit :</p>
            <ul class="puces">
              <li>L'ingrédient est obligatoire afin que Mercudyn puisse proposer les bonnes substitutions d'articles sur vos commandes.</li>
              <li>La désignation correspond à celle du fournisseur sélectionné.</li>
              <li>Si votre produit ne contient aucun allergène, vous devez sélectionner "Aucun Allergène" dans la liste déroulante.</li>
              <li>Le PCB correspond à par combien le produit est vendu et par quelle unité.</li>
              <li>Le prix unitaire correspond à l'unité de vente. Exemple : Si vous mettez un PCB "15 cartons", le prix unitaire correspond au prix d'un carton. Le montant HT est calculé automatiquement en suivant cette règle.</li>
              <li>Le paramétrage facultatif permet de définir la répartition du produit sur différentes unités. Celui-ci est necessaire pour le bon calcul de commandes depuis vos menus et recette ainsi que vos mises en production.</li>
              <li>Exemple : le produit est vendu par 2,5 Kg. La répartition pourra être définie comme ceci :
                "Dans 2,5 Kg je vais avoir 1 Pièce, 2,5 Kg, 0 Litre"
              </li>
              <li>Deux phrases explicatives vous permettent de voir le résultat de votre répartition.</li>
            </ul>
            <p>Lorsque le produit est enregistré, il est visible depuis la page "Mercuriale" et commandable directement depuis vos commandes.</p>
          </div>
        </section>
      </div>
    </div>     
  </div>
</div>
</template>

<script>

import BarreFiltres from '@/components/Divers/BarreFiltres'

export default {
  name: "DocCommandes",
  components: {
    BarreFiltres
  },
  data() {
    return {
      filtres: {
        sections: {
          label: "Choix de votre recherche...",
          champ: "select",
          options: [           
            { valeur: 'sectionCommandes', texte: 'Suivi' },
            { valeur: 'sectionCommandesManuelle', texte:`Suivi: Commande vierge` },
            { valeur: 'sectionSubstitution', texte:`Suivi: Commande vierge - Substitution de produit` },
            { valeur: 'sectionCommandesPrecalculees', texte:`Suivi: Commande précalculées` },
            { valeur: 'sectionMercuriale', texte:`Mercuriale` },
            { valeur: 'sectionFavoris', texte: 'Mercuriale: Favoris' },
            { valeur: 'sectionPasserCommande', texte:`Passer une commande` },            
            { valeur: 'sectionReception', texte:`Réception de commande` },
            { valeur: 'sectionSpeciaux', texte:`Spéciaux` },
            { valeur: 'sectionProduitsHM', texte:`Produit Hors Marché` },
            { valeur: 'sectionCreationProduitsHM', texte:`Création / Modification d'un produit hors marché` },
          ],
          selection: [],
          filtrer(contexte) {
            let filtre = contexte.filtres.sections
            if (filtre.selection) {
              let sectionSelectionnee = document.getElementById(filtre.selection.valeur);
              if (sectionSelectionnee) {
                sectionSelectionnee.scrollIntoView();
              }  
            }            
          }
        },
        // Appeler "this.filtres.initialiser(this)" dans mounted() => génère le tableau de filtres utilisés pour afficher les filtres dans la barre de filtres
        barreGauche: [],
        barreDroite: [],
        initialiser(contexte) {
          contexte.filtres.barreGauche = [ contexte.filtres.sections ]
        }
      },      
    }
  }, 
  methods: {
    appliquerFiltre(filtrer) {
      // Nécessaire pour faire fonctionner les filtres de la barre de filtres
      filtrer(this)
    },   
  },
  mounted() {
    this.filtres.initialiser(this)
  }
}
</script>
