<template>
  <div>
    <ModalAjoutProduits ref="modalAjoutProduits" :commande="commande" :idsFournisseursVerrouilles="idsFournisseursVerrouilles()" @ajouter-articles="ajouterArticles($event)" />
    <ModalImpressionCommande ref="modalImpressionCommande" :commande="commande" :pasDEnvoi="!commandeEnCreationOuErreurMailNonTraitee()" @commande-envoyee="chargerCommande(commande.id)" />
    <ModalSubstitutionArticle ref="modalSubstitutionArticle" :idsBloques="idsFournisseursVerrouilles()" @selection-article="substituerArticle($event)" />
    <ModalChoisirFournisseur ref="modalChoisirFournisseur" :commande="commande" :pasDEnvoi="!commandeEnCreationOuErreurMailNonTraitee()" @commande-envoyee="initialiserCommande($event)" />
    <ModalAjoutProduitHM ref="modalAjoutProduit" :paramModale="{ modeAffichage: 'ajoutProduit' }" @produit-modifie="affecterNouveauProduitHM($event)" />
    <ModalEnvoiCommande ref="modalEnvoiCommande" :commande="commande" @commande-envoyee="chargerCommande(commande.id)" />

    <!-- Contenu de la page -->
    <div class="container-fluid">
      <BarreActions :actions="actions" @appliquer-action="executerAction($event)" />

      <!-- Lignes de commandes -->
      <div class="card table-scroll height-responsive-65">
        <div class="card-header flex-center-space-between">
          <div class="flex-start">
            <span>{{ commande.nom }}</span>
          </div>
          <span v-if="inventaireOuvert" class="text-warning">
            <i class="fas fa-exclamation-triangle text-warning"></i> Inventaire en cours
          </span>
          <span v-if="enAttenteDeValidation" class="text-warning">
            <i class="fas fa-exclamation-triangle text-warning"></i> En attente de validation par le gestionnaire
          </span>
          <span v-if="valideeParGestionnaire" class="text-success">
            <i class="fas fa-info-circle text-success"></i> Validée par le gestionnaire
          </span>
          <span v-if="tarifsRecalcules" class="text-warning">
            <i class="fas fa-exclamation-triangle text-warning"></i> Tarifs obsolètes mis à jour
          </span>
          <div class="flex-end">
            <!--<span class="ml-5">Date de création : {{ commande.dateCreation }}</span>-->
            <span class="ml-5">Lignes de commande : {{ nbLignes(commande) }} / {{ lignesAAfficher().length }}</span>
            <span class="ml-5">Fournisseurs différents : {{ nbFournisseurs(commande) }}</span>
            <span class="ml-5">
              Total HT :
              <em><span v-if="totalPCB(donneesInitiales) !== 0 && totalPCB(commande) !== totalPCB(donneesInitiales)">({{ totalPCB(donneesInitiales) | afficherPrix2 }}) </span></em>
              <span :class="[{'bg-success p-1 pl-2 pr-2':totalPCB(donneesInitiales) !== 0 && totalPCB(commande) < totalPCB(donneesInitiales)}, {'bg-warning p-1 pl-2 pr-2':totalPCB(donneesInitiales) !== 0 && totalPCB(commande) > totalPCB(donneesInitiales)}]">
                <b style="color:inherit">{{ totalPCB(commande) | afficherPrix2 }} </b>
                <i v-if="totalPCB(donneesInitiales) !== 0 && totalPCB(commande) > totalPCB(donneesInitiales)" class="fas fa-arrow-up" style="color:inherit"></i>
                <i v-if="totalPCB(donneesInitiales) !== 0 && totalPCB(commande) < totalPCB(donneesInitiales)" class="fas fa-arrow-down" style="color:inherit"></i>
              </span>
            </span>
            <span class="ml-5">Total TTC : {{ totalTTC(commande) | afficherPrix2 }}</span>
          </div>
        </div>
        <table class="table table-bordered table-sm text-center table-header-secondary"> <!-- style="display:block;max-height:800px;overflow:auto" -->
          <colgroup>
            <col width="40">
            <col>
            <col width="180">
            <col width="75">
            <col width="140">
            <col width="90">
            <col v-if="commandeDepuisMenu()" width="90">
            <col width="150">
            <col width="110">
            <col width="70">
            <col width="90">
            <col width="34">
          </colgroup>
          <thead>
            <th class="watchMe" @click="trierListe('faId', true)">{{ afficheIconeTri('faId') ? "Fa." : "Fam." }}<i v-if="afficheIconeTri('faId')" :class="iconeSensTri"></i></th>
            <th class="aligner-gauche watchMe" @click="trierListe('designation')">Désignation<i v-if="afficheIconeTri('designation')" :class="iconeSensTri"></i></th>
            <th class="watchMe" @click="trierListe('foId', true)">Fournisseur<i v-if="afficheIconeTri('foId')" :class="iconeSensTri"></i></th>
            <th class="watchMe" @click="trierListe('Ref')">Réf.<i v-if="afficheIconeTri('Ref')" :class="iconeSensTri"></i></th>
            <th class="watchMe" @click="trierListe('marque')">Marque<i v-if="afficheIconeTri('marque')" :class="iconeSensTri"></i></th>
            <th class="watchMe" @click="trierListe('qStock', true)">Qté. stock<i v-if="afficheIconeTri('qStock')" :class="iconeSensTri"></i></th>
            <th v-if="commandeDepuisMenu()" class="watchMe" @click="trierListe('qBesoin', true)">Qté. besoin<i v-if="afficheIconeTri('qBesoin')" :class="iconeSensTri"></i></th>
            <th class="watchMe" @click="trierListe('qDem', true)">Qté. demandée<i v-if="afficheIconeTri('qDem')" :class="iconeSensTri"></i></th>
            <th class="watchMe" @click="trierListe('qPCB', true)">Qté. PCB (PCB)<i v-if="afficheIconeTri('qPCB')" :class="iconeSensTri"></i></th>
            <th class="watchMe" @click="trierListe('pu', true)">PU HT<i v-if="afficheIconeTri('pu')" :class="iconeSensTri"></i></th>
            <th class="watchMe" @click="trierListe('totalht', true)">Total HT<i v-if="afficheIconeTri('totalht')" :class="iconeSensTri"></i></th>
            <th></th>
          </thead>
        </table>
        <div class="table-body">
          <table class="table table-bordered table-sm text-center">
            <colgroup>
              <col width="40">
              <col>
              <col width="180">
              <col width="75">
              <col width="140">
              <col width="90">
              <col v-if="commandeDepuisMenu()" width="90">
              <col width="150">
              <col width="110">
              <col width="70">
              <col width="90">
              <col width="29">
            </colgroup>
            <tbody>
              <tr :class="[{'ligne-selectionnee':idLignePourSelection(ligne) === idLigneSelectionnee}, {'ligne-modifiee':estModifiee(ligne)}, {'ligne-supprimee':ligne.deleted}, {'ligne-desactivee':commandeFournisseurEnvoyee(ligne.foId) || commandeFournisseurReceptionnee(ligne.foId)}]"
                v-for="(ligne, iLigne) in lignesFiltreesTrieesAAfficher" :key="iLigne" @click="selectionnerLigneCommande(ligne)">
                <template v-if="ligne.pId">
                  <td>{{ codeFamille(ligne.faId) }}</td>
                  <td class="aligner-gauche">
                    {{ ligneDesignation(ligne) }}
                    <em v-if="!ligne.ingId" class="warning ml-2">
                      <i class="fas fa-exclamation-triangle ligne-modifiee"></i> Produit sans ingrédient
                    </em>
                  </td>
                  <td :class="['input',{'bg-error':!ligne.foId}]">
                    <span class="flex-space-between">
                      <span>{{ nomFournisseur(ligne.foId) }}</span>
                      <button type="button" class="btn btn-primary btn-sm" :disabled="!commandeFournisseurEnCreation(ligne.foId) || valideeParGestionnaire" @click.stop="showSubstitutionArticle(ligne)">
                        <i class="fas fa-exchange-alt"></i>
                      </button>
                    </span>
                  </td>
                  <td>{{ ligne.Ref }}</td>
                  <td>{{ ligne.marque }}</td>
                  <td>{{ (ligne.qStock || 0) | afficherArrondi }} {{ ligne.uc }}</td>
                  <td v-if="commandeDepuisMenu()">
                    <span v-if="ligne.qBesoin">{{ ligne.qBesoin | afficherArrondi }} <span  :class="{'text-warning': !ucIdentiques(ucBesoin(ligne), ligne.uc)}">{{ ucBesoin(ligne) }}</span></span>
                    <span v-else>0 <span  :class="{'text-warning': !ucIdentiques(ucBesoin(ligne), ligne.uc)}">{{ ucBesoin(ligne) }}</span></span>
                  </td>
                  <td class="input">
                    <span v-if="commandeFournisseurEnCreation(ligne.foId) && !valideeParGestionnaire" class="flex-center-space-between">
                      <span v-if="ligne.uModifiees" class="input-group-text"><i class="fas fa-exclamation-triangle error"></i></span>
                      <label class="flex-end h-100 p-0 m-0">
                        <InputNumber ref="inputNumber" class="form-control" v-model="ligne.qDem" :data-lignepid="ligne.pId" typeFiltre="quantite" zeroSiVide="1" :fixBlur="true" @move="deplacerSelectionLigneCommande($event)" @focus="selectionnerLigneCommande(ligne)" @save="qteDemChangee(ligne, $event)" @blur="qteDemChangee(ligne, $event)" />
                        <span :class="['input-group-text', {'text-warning': !ucIdentiques(ucBesoin(ligne), ligne.uc)}]">{{ ligne.uc }}</span>
                      </label>
                      <span class="input-group-btn">
                        <button v-if="ligne.uModifiees" type="button" class="btn btn-primary btn-sm" @click.stop="effacerAlerteRecalcul(ligne)">
                          <i class="fas fa-check"></i>
                        </button>
                      </span>
                    </span>
                    <span v-else>
                      {{ ligne.qDem | afficherArrondi }} {{ ligne.uc }}
                    </span>
                  </td>
                  <td :class="[{'bg-warning':ligne.qDem !== qtePcb(ligne)}]">{{ qtePcb(ligne) | afficherArrondi }} {{ ligne.uc }} <span v-if="ligne.pcb">({{ ligne.pcb | afficherArrondi }})</span></td>
                  <td class="aligner-droite"><span v-if="ligne.pu">{{ ligne.pu | afficherPrix }}</span></td>
                  <td class="aligner-droite" :class="[{'bg-warning':ligne.qDem !== qtePcb(ligne)}]"><span v-if="ligne.pu">{{ ligneTotalPCB(ligne) | afficherPrix2 }}</span></td>
                  <td class="input">
                    <button type="button" class="btn btn-danger btn-sm ml-0" :disabled="!commandeFournisseurEnCreation(ligne.foId) || valideeParGestionnaire" @click.stop="supprimerLigne(ligne)">
                      <i class="fas fa-trash"></i>
                    </button>
                  </td>
                </template>
                <template v-else-if="ligne.recIngId && getProduits.find(p => p.ingredientId === ligne.recIngId)">
                  <td :colspan="commandeDepuisMenu() ? 12 : 11" :class="['aligner-gauche', {'bg-error':idLignePourSelection(ligne) !== idLigneSelectionnee}, {'text-error':idLignePourSelection(ligne) === idLigneSelectionnee}]">
                    <!-- Cas d'un ingrédient de recette sans cotation en mercuriale avec répartition UP/UC incomplète -->
                    Sélection automatique impossible pour l'ingrédient : "{{ ligneDesignation(ligne) }}" - {{ ligne.qBesoin | afficherArrondi }} {{ ligne.utilisations && ligne.utilisations[0] && ligne.utilisations[0].uc }}
                    &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;Sélectionnez un produit manuellement, puis renseigner la quantité :
                    <!-- Mauvaise configuration entre la recette et les produits de l'ingrédient : {{ ligneDesignation(ligne) }} - {{ ligne.qBesoin | afficherArrondi }} {{ ligne.utilisations && ligne.utilisations[0] && ligne.utilisations[0].uc }} -->
                    <!-- &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;Choisissez un produit : -->
                    <button type="button" class="btn btn-primary btn-sm ml-3" :disabled="!commandeFournisseurEnCreation(ligne.foId)" @click.stop="showSubstitutionArticle(ligne)">
                      <i class="fas fa-exchange-alt"></i>
                    </button>
                  </td>
                </template>
                <template v-else-if="ligne.recIngId">
                  <td :colspan="commandeDepuisMenu() ? 12 : 11" :class="['aligner-gauche', {'bg-error':idLignePourSelection(ligne) !== idLigneSelectionnee}, {'text-error':idLignePourSelection(ligne) === idLigneSelectionnee}]">
                    <!-- Cas d'un ingrédient de recette sans cotation en mercuriale -->
                    Aucun prix disponible pour l'ingrédient : {{ ligneDesignation(ligne) }} - {{ ligne.qBesoin | afficherArrondi }} {{ ligne.utilisations && ligne.utilisations[0] && ligne.utilisations[0].uc }}
                    &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;Créez un produit hors marché :
                    <button type="button" class="btn btn-primary btn-sm" :disabled="!commandeFournisseurEnCreation(ligne.foId)" @click.stop="showAjoutProduitHM(ligne)">
                      <i class="fas fa-plus"></i>
                    </button>
                  </td>
                </template>
                <template v-else>
                  <td :colspan="commandeDepuisMenu() ? 12 : 11" :class="['aligner-gauche', {'bg-error':idLignePourSelection(ligne) !== idLigneSelectionnee}, {'text-error':idLignePourSelection(ligne) === idLigneSelectionnee}]">
                    <!-- Cas d'un ingrédient de recette sans cotation en mercuriale -->
                    Ingrédient indisponible, veuillez cliquer sur le bouton "Recalculer besoins" : {{ ligneDesignation(ligne) }} (Aucune cotation disponible) - {{ ligne.qBesoin | afficherArrondi }} {{ ligne.utilisations && ligne.utilisations[0] && ligne.utilisations[0].uc }}
                  </td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="flex-center-space-between">
        <!-- Utilisations dans les menus -->
        <div v-if="commandeDepuisMenu()" class="card table-scroll height-responsive-35 mr-1">
          <div class="card-header">
            <span v-if="ligneSelectionnee">Recettes contenant le produit "{{ ligneDesignation(ligneSelectionnee) }}"</span>
            <span v-else>Cliquez sur une ligne produit pour voir les recettes correspondantes</span>
          </div>
          <table class="table table-bordered table-sm text-center table-header-secondary">
            <colgroup>
              <col>
              <col>
              <col width="90">
              <col width="90">
              <col width="90">
              <col width="70">
              <col width="95">
            </colgroup>
            <thead>
              <th class="aligner-gauche">Recette</th>
              <th class="col-fixed-width-100-sm col-fixed-width-200-lg col-fixed-width-300-xl">Structure</th>
              <th>Date conso.</th>
              <th>Service</th>
              <th>Quantité</th>
              <th>Effectifs</th>
              <th>Date livraison</th>
            </thead>
          </table>
          <div class="table-body">
            <table class="table table-bordered table-sm text-center">
              <colgroup>
                <col>
                <col>
                <col width="90">
                <col width="90">
                <col width="90">
                <col width="70">
                <col width="90">
              </colgroup>
              <tbody v-if="ligneSelectionnee">
                <tr :class="[{'ligne-selectionnee':idUtilisationPourSelection(utilisation) === idUtilisationSelectionnee}, {'ligne-modifiee':utilisation.recetteEnregistree != null || utilisation.quantiteEnregistree != null || utilisation.ucEnregistree != null || utilisation.effectifsEnregistres != null}, {'ligne-supprimee':!utilisation.recette || !utilisation.quantite}, {'ligne-verrouillee':commandeFournisseurEnvoyee(ligneSelectionnee.foId) || commandeFournisseurReceptionnee(ligneSelectionnee.foId)}]"
                  v-for="(utilisation, iUtilisation) in ligneSelectionnee.utilisations" :key="iUtilisation" @click="selectionnerUtilisation(utilisation)">
                  <td class="aligner-gauche">
                    <i v-if="utilisation.recette.estUneDeclinaison && !utilisation.recette.estDeclinaisonPersonnalisee" class="fas fa-exclamation-triangle text-warning" v-tooltip="{ content: 'Configuration de recette non vérifiée', placement: 'top' }"></i>
                    <RecettePopUpIngredients :estLien="true" :recetteId="utilisation.recette.id" :recetteNom="utilisation.recette.nom" @recette-enregistree="recetteEnregistree($event)" @recette-enregistree-modifiee="recetteEnregistreeModifiee($event)" />
                    <span v-if="utilisation.recetteEnregistree != null" class="ligne-supprimee">
                      (<i v-if="utilisation.recetteEnregistree.estUneDeclinaison && !utilisation.recetteEnregistree.estDeclinaisonPersonnalisee" class="fas fa-exclamation-triangle text-warning" v-tooltip="{ content: 'Configuration de recette non vérifiée', placement: 'top' }"></i>
                      <RecettePopUpIngredients :estLien="true" :recetteId="utilisation.recetteEnregistree.id" :recetteNom="utilisation.recetteEnregistree.nom" @recette-enregistree="recetteEnregistree($event)" @recette-enregistree-modifiee="recetteEnregistreeModifiee($event)" />)
                    </span>
                  </td>
                  <td class="col-fixed-width-100-sm col-fixed-width-200-lg col-fixed-width-300-xl">{{ utilisation.structure }}</td>
                  <td>{{ formatHumanDateAvecSlashes(utilisation.dateConsommation) }}</td>
                  <td>{{ utilisation.service }}</td>
                  <td>
                    {{ utilisation.quantite | afficherArrondi }} {{ utilisation.uc }}
                    <span v-if="utilisation.quantiteEnregistree != null" class="ligne-supprimee">({{ utilisation.quantiteEnregistree | afficherArrondi }} {{ utilisation.ucEnregistree }})</span>
                  </td>
                  <td>
                    {{ utilisation.effectifs }}
                    <span v-if="utilisation.effectifsEnregistres != null" class="ligne-supprimee">({{ utilisation.effectifsEnregistres }})</span>
                  </td>
                  <td>{{ formatHumanDateAvecSlashes(utilisation.dateLivraisonSouhaitee) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- Détails fournisseurs -->
        <div class="card table-scroll height-responsive-35" :class="{'ml-1':commandeDepuisMenu()}">
          <div class="card-header flex-center-space-between">
            <span>Commandes fournisseurs à envoyer / réceptionner</span>
            <span>
              <label class="label-checkbox mt-1">
                <span class="ml-2">Filtrer selon le fournisseur sélectionné</span>
                <ToggleButton class="ml-2 mt-1" v-model="filtrerLignesSurFournisseur" :color="{checked: '#16D39A', unchecked: '#CCCCCC'}" :height="12" :width="20" @change="activerFiltreLignesSurFournisseurs()" />
              </label>
            </span>
          </div>
          <table class="table table-bordered table-sm text-center table-header-secondary">
            <colgroup>
              <col>
              <col width="60">
              <col width="85">
              <col width="80">
              <col width="80">
              <col width="90">
              <col width="79">
            </colgroup>
            <thead>
              <th class="aligner-gauche">Fournisseur</th>
              <th>Lignes</th>
              <th>Bon comm.</th>
              <th>Livraison</th>
              <th>Franco HT</th>
              <th>Total HT</th>
              <th class="aligner-gauche pl-1">
                <button v-if="commandeEnCreationOuErreurMailNonTraitee()" type="button" class="btn btn-primary btn-sm ml-0" @click.stop="genererBdC()">
                  <i class="fas fa-envelope"></i>
                </button>
                <button v-if="commandeEnCreationOuErreurMailNonTraitee()" type="button" class="btn btn-primary btn-sm" @click.stop="ouvrirEnvoiSansMail()">
                  <i class="fas fa-phone"></i>
                </button>
              </th>
            </thead>
          </table>
          <div class="table-body">
            <table class="table table-bordered table-sm text-center">
              <colgroup>
                <col>
                <col width="60">
                <col width="85">
                <col width="80">
                <col width="80">
                <col width="90">
                <col width="74">
              </colgroup>
              <tbody>
                <tr :class="[{'ligne-selectionnee':fournisseur.fournisseurId === idFournisseurSelectionne}, {'ligne-modifiee':fournisseurEstModifie(fournisseur)}, {'ligne-supprimee':!nbLignes(commande, fournisseur.fournisseurId)}, {'ligne-desactivee':commandeFournisseurReceptionnee(fournisseur.fournisseurId)}]"
                  v-for="(fournisseur, iFournisseur) in fournisseursAAfficher" :key="iFournisseur" @click="selectionnerLigneFournisseur(fournisseur.fournisseurId)">
                  <td :class="['aligner-gauche', {'text-success':commandeFournisseurEnvoyee(fournisseur.fournisseurId)}]">
                    <i v-if="commandeFournisseurEnCreation(fournisseur.fournisseurId)" class="fas fa-shopping-cart" v-tooltip="{ content: 'Commande en cours d\'élaboration'}"></i>
                    <i v-if="commandeFournisseurEnvoyee(fournisseur.fournisseurId)" class="fas fa-shipping-fast ligne-modifiee" v-tooltip="{ content: 'Commande envoyée au fournisseur, en attente de livraison'}"></i>
                    <i v-if="commandeFournisseurReceptionnee(fournisseur.fournisseurId)" class="fas fa-boxes ligne-modifiee" v-tooltip="{ content: 'Commande réceptionnée'}"></i>
                    {{ nomFournisseur(fournisseur.fournisseurId) }}
                    <em v-if="commandeFournisseurEnvoyeeManuellement(fournisseur.fournisseurId)">
                      <span v-if="commandeFournisseurEnErreurMail(fournisseur.fournisseurId)"> (Erreur de mail, traitée manuellement le {{ formatHumanDateAvecSlashes(fournisseur.dateEnvoiManuel) }})</span>
                      <span v-else> (Envoyée manuellement le {{ formatHumanDateAvecSlashes(fournisseur.dateEnvoiManuel) }})</span>
                    </em>
                    <em v-else-if="commandeFournisseurEnErreurMail(fournisseur.fournisseurId)" class="error ml-2">
                      <i class="fas fa-exclamation-triangle ligne-modifiee"></i> Erreur d'envoi du mail
                    </em>
                    <em v-if="estAttenteValidationCommande && commandeFournisseurInitialeValidee(fournisseur.fournisseurId)">
                      <span class="text-success ml-3"><i class="fas fa-info-circle"></i> Validé par le gestionnaire</span>
                    </em>
                  </td>
                  <td>{{ nbLignes(commande, fournisseur.fournisseurId) }}</td>
                  <td class="input">
                    <input :id="'fourn' + fournisseur.fournisseurId" type="text" v-if="commandeFournisseurEnCreation(fournisseur.fournisseurId) && nbLignes(commande, fournisseur.fournisseurId)" class="form-control" v-model.trim="fournisseur.bonDeCommande" maxlength="80" @focus="$event.target.select()" @keyup="deplacerSelectionFournisseur($event)">
                    <span v-else>{{ fournisseur.bonDeCommande }}</span>
                  </td>
                  <td class="input">
                    <v-date-picker v-if="commandeFournisseurEnCreation(fournisseur.fournisseurId) && nbLignes(commande, fournisseur.fournisseurId)" class="date-picker" v-model="fournisseur.dateLivraisonSouhaitee" :attributes="attributsDPsCommande(fournisseur.fournisseurId)" :popover="{ visibility: 'click' }" @input="setDateLivraisonSouhaitee(fournisseur.fournisseurId, $event)">
                      <template v-slot="{ inputValue, inputEvents }">
                        <input :class="['form-control', {'incorrect':!fournisseur.dateLivraisonSouhaitee}]" :value="inputValue ? inputValue : 'Date...'" v-on="inputEvents" @focus="selectionnerFournisseur(fournisseur.fournisseurId)" />
                      </template>
                    </v-date-picker>
                    <span v-else>{{ commandeFournisseurReceptionnee(fournisseur.fournisseurId) ? formatHumanDateAvecSlashes(fournisseur.dateLivraison) : formatHumanDateAvecSlashes(fournisseur.dateLivraisonSouhaitee) }}</span>
                  </td>
                  <td class="aligner-droite" :class="[{'bg-warning':totalPCB(commande, fournisseur.fournisseurId) < francoFournisseur(fournisseur.fournisseurId)}]">{{ francoFournisseur(fournisseur.fournisseurId) }} €</td>
                  <td class="aligner-droite">{{ totalPCB(commande, fournisseur.fournisseurId) | afficherPrix2 }}</td>
                  <td class="input">
                    <button type="button" class="btn btn-primary btn-sm ml-0" :disabled="nbLignes(commande, fournisseur.fournisseurId) === 0 || (estAttenteValidationCommande && !valideeParGestionnaire)" @click.stop="envoyerBdCParMail(fournisseur.fournisseurId)">
                      <i class="fas" :class="commandeFournisseurEnCreationOuErreurMailNonTraitee(fournisseur.fournisseurId) ? /*'fa-paper-plane'*/'fa-envelope' : 'fa-print'"></i>
                    </button>
                    <button type="button" class="btn btn-primary btn-sm" v-if="commandeFournisseurEnCreationOuErreurMailNonTraitee(fournisseur.fournisseurId)" :disabled="nbLignes(commande, fournisseur.fournisseurId) === 0 || (estAttenteValidationCommande && !valideeParGestionnaire)" @click.stop="envoyerBdCSansMail(fournisseur.fournisseurId)">
                      <i class="fas fa-phone"></i>
                    </button>
                    <button type="button" class="btn btn-primary btn-sm" v-else-if="commandeFournisseurEnvoyee(fournisseur.fournisseurId)" @click.stop="ouvrirDetailCommandeFournisseur(fournisseur.fournisseurId)">
                      <!--<i class="fas fa-truck-loading"></i>-->
                      <i class="fas fa-dolly-flatbed"></i>
                      <!--<i class="fas fa-truck"></i>-->
                    </button>
                    <button type="button" class="btn btn-primary btn-sm" v-else-if="commandeFournisseurReceptionnee(fournisseur.fournisseurId)" @click.stop="ouvrirDetailCommandeFournisseur(fournisseur.fournisseurId)">
                      <i class="fas fa-eye"></i>
                    </button>
                    <button type="button" class="btn btn-danger btn-sm" :disabled="!commandeFournisseurEnCreationOuErreurMailNonTraitee(fournisseur.fournisseurId) || nbLignes(commande, fournisseur.fournisseurId) === 0 || valideeParGestionnaire" @click.stop="supprimerFournisseur(fournisseur.fournisseurId)">
                      <i class="fas fa-trash"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash"
import BarreActions from '@/components/Divers/BarreActions'
import InputNumber from '@/components/Inputs/InputNumber'
import ModalAjoutProduits from '@/components/Commandes/ModalAjoutProduits'
import ModalSubstitutionArticle from '@/components/Divers/ModalSubstitutionArticle'
import ModalImpressionCommande from '@/components/Commandes/ModalImpressionCommande'
import ModalEnvoiCommande from '@/components/Commandes/ModalEnvoiCommande'
import ModalChoisirFournisseur from '@/components/Commandes/ModalChoisirFournisseur'
import RecettePopUpIngredients from '@/components/Recettes/RecettePopUpIngredients'
import ModalAjoutProduitHM from '@/components/Divers/ModalAjoutProduitHM'
import { detailsCommande, recalculerCommande, optimiserpcbCommande, optimiserfournisseurCommande, optimiserfrancoCommande, modifierCommande, envoyerCommandeFournisseurs, envoyerBC, supprimerUnFournisseur, demanderValidationCommande } from '@/api/gpao/commandes'
import { verifierInventaireOuvert } from "@/helpers/verifications"
import { obtenirBC } from '@/api/gpao/documents'
import { commande } from "@/mixins/commandes"
import { arrondiNombre } from '@/helpers/utils'
import { connecteData } from "@/mixins/connecteData"
import { alerteDonneesModifiees } from "@/mixins/index"
import { showAlerts } from '@/mixins/alerts'
import { codeFamille } from '@/mixins/recette'
import { formatageDate, formatHumanDateAvecSlashes } from '@/mixins/dateFormats'
import { qtePCB, prixPCB } from '@/helpers/utils'
import { constantesId } from "@/helpers/constantes"
import { saveAs } from 'file-saver'
import { mapGetters } from "vuex"

export default {
  name: "DetailCommande",
  mixins: [ connecteData, commande, showAlerts, alerteDonneesModifiees, codeFamille, formatageDate, formatHumanDateAvecSlashes ],
  components: {
    BarreActions,
    InputNumber,
    ModalAjoutProduits,
    ModalSubstitutionArticle,
    ModalImpressionCommande,
    ModalEnvoiCommande,
    ModalChoisirFournisseur,
    ModalAjoutProduitHM,
    RecettePopUpIngredients
  },
  data() {
    return {
      triCmd: null,
      triNomColonne: "",
      triColonneNumerique: false,
      commande: {},
      inventaireOuvert: false,
      idLigneSelectionnee: null,
      idFournisseurSelectionne: null,
      idUtilisationSelectionnee: null,
      filtrerLignesSurFournisseur: false,
      sortBy: "indice", //défini le tri par defaut des lignes
      sortOrder: 1,
      actions: {
        // Boutons à afficher dans la barre d'action
        // Exemple :
        /*
        nomAction: {
          label: Label affiché sur le bouton (string),
          hidden: Affiche/masque le bouton (bool),
          disabled: Etat actif/inactif par défaut du bouton (bool),
          class: Classe CSS (btn-primary, btn-success, btn-warning ou btn-danger) à appliquer sur le bouton (par défaut : "btn-primary" est appliqué => il s'agit du bouton bleu Agap'pro) (string)
          methode(contexte) {
            // => contexte équivaut à this (mais this ne fonctionne pas car l'initialisation de data n'est pas encore terminée, donc data n'est pas encore rattaché à this)
            traitements à exécuter lors du clic sur le bouton
            les traitements définis dans cette page doivent être appelés sous la forme : contexte.traitement()

            Remarque : pour que le système fonctionne, il faut déclarer la méthode ci-dessous dans la section "methods" de cette page
            executerAction(methode) {
              methode(this)
            }

          }
        }
        */
        ajouterArticles: {
          label: "Choisir produits",
          methode(contexte) {
            contexte.showAjoutArticles()
          }
        },
        /*optimiserPCB: {
          label: "Optimiser au PCB",
          methode(contexte) {
            //contexte.showAjoutCommande()
          }
        },
        optimiserStock: {
          label: "Optimiser au stock",
          methode(contexte) {
            //contexte.showAjoutCommande()
          }
        },*/
        genererBdC1Tableau: {
          label: "Exporter",
          disabled: this.impressionCmd1TableauEnCours,
          methode(contexte) {
            contexte.generer(contexte.commande, contexte.lignesFiltreesTrieesAAfficher)
          }
        },
        genererBdC: {
          label: "Bon de commande",
          disabled: false,
          async methode(contexte) {
            contexte.genererBdC()
          }
        },
        demandeValidation: {
          label: "Valider",
          hidden: true,
          disabled: false,
          initialiser(contexte) {
            contexte.actions.demandeValidation.hidden = !contexte.estAttenteValidationCommande
          },
          async methode(contexte) {
            contexte.demandeValidationCommande()
          }
        },
        recalculer: {
          label: "Recalculer besoins",
          hidden: false,
          disabled: false,
          methode(contexte) {
            contexte.recalculer()
          }
        },
        optimiserPCB: {
          label: "Optimiser prix",
          hidden: false,
          disabled: false,
          methode(contexte) {
            contexte.optimiserpcb()
          }
        },
        optimiserFournisseur: {
          label: "Optimiser fournisseurs",
          hidden: false,
          disabled: false,
          methode(contexte) {
            contexte.optimiserfournisseur()
          }
        },
        optimiserFranco: {
          label: "Optimiser franco",
          hidden: false,
          disabled: false,
          methode(contexte) {
            contexte.optimiserfranco()
          }
        },
        changerFournisseur: {
          label: "Choisir fournisseur",
          disabled: false,
          async methode(contexte) {
            contexte.choisirFournisseur()
          }
        },
        retour: {
          label: "Retour",
          methode(contexte) {
            contexte.$router.push({ name: "ListeDesCommandes", params: { etablissementId: contexte.etabCourantId } })
          }
        },
        annuler: {
          label: "Annuler",
          disabled: true,
          class: "btn-danger",
          async methode(contexte) {
            let result = await contexte.alerteConfirmation("Annuler les modifications", "Vos modifications seront définitivement perdues.<br/>Souhaitez-vous annuler vos modifications ?")
            if (result) {
              contexte.commande = _.cloneDeep(contexte.donneesInitiales)
              contexte.actions.disableSiEnvoyeOuReceptionne(contexte.actions, contexte.commandeEnvoyeeOuReceptionnee())
              contexte.actions.recalculer.hidden = !contexte.commandeDepuisMenu()
            }
          }
        },
        enregistrer: {
          label: "Enregistrer",
          disabled: true,
          class: "btn-success",
          methode(contexte) {
            contexte.enregistrer()
          }
        },
        monter: {
          label: "",
          icone: "fas fa-caret-up",
          disabled: true,
          methode(contexte) {
            contexte.mouvement(1)
          }
        },
        descendre: {
          label: "",
          icone: "fas fa-caret-down",
          disabled: true,
          methode(contexte) {
            contexte.mouvement(-1)
          }
        },
        // Appeler "this.actions.initialiser(this)" dans mounted() => génère les tableaux d'actions utilisés pour afficher les boutons à gauche et à droite de la barre d'actions
        barreGauche: [],
        barreDroite: [],
        initialiser(contexte) {
          contexte.actions.barreGauche = [ contexte.actions.ajouterArticles, contexte.actions.recalculer, contexte.actions.optimiserPCB, contexte.actions.optimiserFournisseur, contexte.actions.changerFournisseur, contexte.actions.genererBdC1Tableau, contexte.actions.genererBdC, contexte.actions.demandeValidation, contexte.actions.monter, contexte.actions.descendre ]
          contexte.actions.barreDroite = [ contexte.actions.retour, contexte.actions.annuler, contexte.actions.enregistrer ]
          contexte.actions.barreGauche.forEach(f => {
            if (f.initialiser) {
              f.initialiser(contexte)
            }
          })
          contexte.actions.barreDroite.forEach(f => {
            if (f.initialiser) {
              f.initialiser(contexte)
            }
          })
        },
        // A gérer en cas de boutons à activer/désactiver (créer une méthode par groupe de boutons à activer/désactiver)
        disableEnregistrement(actions, disabled) {
          let actionsATraiter = [ actions.annuler, actions.enregistrer ]
          actionsATraiter.forEach(a => a.disabled = disabled)
        },
        disableSiNouveauTarif(actions, disabled) {
          let actionsATraiter = [ actions.annuler ]
          actionsATraiter.forEach(a => a.disabled = disabled)
        },
        disableSelectionLigneMonter(actions, disabled) {
          let actionsATraiter = [ actions.monter ]
          actionsATraiter.forEach(a => a.disabled = disabled)
        },
        disableSelectionLigneDescendre(actions, disabled) {
          let actionsATraiter = [ actions.descendre ]
          actionsATraiter.forEach(a => a.disabled = disabled)
        },
        disableSiEnvoyeOuReceptionne(actions, disabled) {
          let actionsATraiter = [ actions.ajouterArticles, actions.recalculer, actions.optimiserFournisseur, actions.optimiserFranco, actions.optimiserPCB, actions.changerFournisseur ]
          actionsATraiter.forEach(a => a.disabled = disabled)
        },
        disableSiCommandeVide(actions, disabled) {
          let actionsATraiter = [ actions.optimiserPCB, actions.optimiserFournisseur, actions.changerFournisseur, actions.genererBdC1Tableau, actions.genererBdC, actions.demandeValidation ]
          actionsATraiter.forEach(a => a.disabled = disabled)
        },
        disableDemandeValidation(actions, disabled) {
          let actionsATraiter = [ actions.demandeValidation ]
          actionsATraiter.forEach(a => a.disabled = disabled)
        },
        disableBonDeCommande(actions, disabled) {
          let actionsATraiter = [ actions.genererBdC ]
          actionsATraiter.forEach(a => a.disabled = disabled)
        }
      }
    }
  },
  computed: {
    ...mapGetters("definitions", ["environnementProduction"]),
    ...mapGetters("fournisseurs", ["nomFournisseur", "francoFournisseur"]),
    ...mapGetters("produits", ["getProduits"]),
    donneesModifiees() {
      if (this.fournisseursAAfficher.some(f => this.fournisseurEstModifie(f))) {
        return true
      }
      return this.lignesAAfficher().some(l => this.estModifiee(l))
    },
    tarifsRecalcules() {
      return this.lignesAAfficher().some(l => this.tarifObsolete(l))
    },
    enAttenteDeValidation() {
      return this.commandeEnAttenteValidation()
    },
    valideeParGestionnaire() {
      return this.commandeValide()
    },
    lignesFiltreesAAfficher() {
      let lignes = this.lignesAAfficher()
      this.actions.disableSelectionLigneMonter(this.actions, !this.lignePeutMonter())
      this.actions.disableSelectionLigneDescendre(this.actions, !this.lignePeutDescendre())
      if (this.filtrerLignesSurFournisseur && this.fournisseurSelectionne()) {
        lignes = lignes.filter(l => l.foId === this.fournisseurSelectionne().fournisseurId)
      }
      return lignes
    },
    lignesFiltreesTrieesAAfficher() {
      let lignesTriees = []
      let lignesRouge = []
      if (this.triCmd == null)
        lignesTriees = [...this.lignesFiltreesAAfficher].sort((a, b) => {if ((a[this.sortBy] ? a[this.sortBy] : 0) >= (b[this.sortBy] ? b[this.sortBy] : 0)) { return this.sortOrder } return -this.sortOrder})
      else {
        let sens = this.triCmd
        let nomCol = this.triNomColonne
        let num = this.triColonneNumerique
        //Il ne faut pas trier les lignes rouges
        lignesRouge = this.lignesFiltreesAAfficher.filter(x => !x.pId)
        lignesTriees = [...this.lignesFiltreesAAfficher.filter(x => x.pId)].sort((a, b) => {
          //Dans le cas d'un Tri par fournisseur, si l'Id est null ou undefined, on met * en désignation, les lignes seront en premier par ordre croissant ou dernier par ordre décroissant
          let cola = nomCol === 'foId' ? (a[nomCol] ? this.nomFournisseur(a[nomCol]): '*') : (nomCol === 'faId' ? this.codeFamille(a[nomCol]) : ( nomCol === 'totalht' ? this.ligneTotalPCB(a) : (nomCol === 'designation' ? this.ligneDesignation(a) : a[nomCol] ) ))
          let colb = nomCol === 'foId' ? (b[nomCol] ? this.nomFournisseur(b[nomCol]): '*') : (nomCol === 'faId' ? this.codeFamille(b[nomCol]) : ( nomCol === 'totalht' ? this.ligneTotalPCB(b) : (nomCol === 'designation' ? this.ligneDesignation(b) : b[nomCol] ) ))

          let i = sens ? (num ? cola || 0 : cola?.toLowerCase() || '')  : (num ? colb || 0 : colb?.toLowerCase() || '')
          let j = sens ? (num ? colb || 0 : colb?.toLowerCase() || '') : (num ? cola || 0 : cola?.toLowerCase() || '')

          //Pour que ça fonctionne aussi bien en numérique que Texte, on fait les tests
          if (i < j)
            return -1
          
          if (i > j)
            return 1
          
          return 0
        })
      }
      return lignesRouge.concat(lignesTriees)
    },
    fournisseursAAfficher() {
      return this.commande?.detailFournisseurs?.filter(f => this.lignesAAfficher().some(l => l.foId === f.fournisseurId) || this.fournisseurEstModifie(f)) || []
    },
    ligneSelectionnee() {
      // L'unicité des lignes pour la sélection se fait sur le produitId/fournisseurId
      return this.commande?.lignes?.find(l => this.idLignePourSelection(l) === this.idLigneSelectionnee)
    },
    iconeSensTri() {
      return this.triCmd ? "ml-1 fas fa-caret-up" : "ml-1 fas fa-caret-down"
    }
  },
  watch: {
    donneesModifiees() {
      this.actions.disableEnregistrement(this.actions, !this.donneesModifiees)
      if (this.donneesModifiees && this.tarifsRecalcules)
        this.actions.disableSiNouveauTarif(this.actions, this.donneesModifiees && this.tarifsRecalcules)

      this.actions.disableSiCommandeVide(this.actions, this.lignesAAfficher().length == 0)
      if (this.lignesAAfficher().length > 0) {
        this.actions.disableSiEnvoyeOuReceptionne(this.actions, this.commandeEnvoyeeOuReceptionnee() || this.commandeValide())
        this.actions.disableDemandeValidation(this.actions, this.commandeEnAttenteValidation() || this.commandeValide() || this.commandeEnvoyeeOuReceptionnee())
        this.actions.disableBonDeCommande(this.actions, this.estAttenteValidationCommande && !this.commandeValide())
      }

    },
    ligneSelectionnee() {
      this.actions.disableSelectionLigneMonter(this.actions, !this.lignePeutMonter())
      this.actions.disableSelectionLigneDescendre(this.actions, !this.lignePeutDescendre())
    },
    impressionCmd1TableauEnCours() {
      this.actions.genererBdC1Tableau.disabled = this.impressionCmd1TableauEnCours
    }
  },
  methods: {
    afficheIconeTri(col) {
      return this.triCmd != null && this.triNomColonne == col
    },
    trierListe(nomColonne, triNumerique) {
      this.triNomColonne = nomColonne
      this.triColonneNumerique = triNumerique
      //changement du sens du tri
      //null --> true --> false
      this.triCmd = this.triCmd == null ? true : (!this.triCmd ? null : false)
    },
    async enregistrerDonneesModifiees() {
      await this.enregistrer()
    },
    triLigne(sortBy) {
      if (this.sortBy === sortBy)
        this.sortOrder = -this.sortOrder
      else
        this.sortBy = sortBy
    },
    minIndice() {
      let lignes = this.lignesAAfficher().filter(l => l.pId)
      if (!lignes?.length) {
        return 1
      }
      return lignes.reduce((p, c) => p.indice < c.indice ? p : c).indice
    },
    maxIndice() {
      let lignes = this.lignesAAfficher().filter(l => l.pId)
      if (!lignes?.length) {
        return 1
      }
        return lignes.reduce((p, c) => p.indice > c.indice ? p : c).indice
    },
    initIndice() {
      //initialise les indices sur toutes les lignes si aucun indice n'existe
      let ToutesLignes = this.lignesAAfficher()
      if (ToutesLignes.reduce((accu, val) => accu + val.indice, 0) == 0) {
        //il faut définir les indices sur toutes les lignes
        var idx = 1
        ToutesLignes.forEach(l => {
          l.indice = idx++
        }) 
      }
    },
    lignePeutMonter() {
      // On peut monter la ligne sélectionnée tant qu'elle n'est pas sur la première ligne et si les lignes affichées ne sont pas filtrées sur un fournisseur et si la commande n'est pas envoyé/réceptionné
      return this.triCmd == null && this.ligneSelectionnee?.pId && this.ligneSelectionnee.indice > this.minIndice() && (!this.filtrerLignesSurFournisseur || !this.fournisseurSelectionne()) && !this.commandeEnvoyeeOuReceptionnee()
    },
    lignePeutDescendre() {
      // On peut descendre la ligne sélectionnée tant qu'elle n'est pas sur la dernière ligne et si les lignes affichées ne sont pas filtrées sur un fournisseur
      return this.triCmd == null && this.ligneSelectionnee?.pId && this.ligneSelectionnee.indice < this.maxIndice() && (!this.filtrerLignesSurFournisseur || !this.fournisseurSelectionne()) && !this.commandeEnvoyeeOuReceptionnee()
    },
    mouvement(sens) {
      //sens : 1 pour monter la ligne et -1 pour descendre
      this.initIndice()
      let ligne = this.ligneSelectionnee
      let minIndice = this.minIndice()
      let maxIndice = this.maxIndice()
      if (ligne.indice != 0 && ((sens > 0 && ligne.indice > minIndice) || (sens < 0 && ligne.indice < maxIndice))) {
        let ligneVoisine = this.lignesAAfficher().find(l => l.indice === ligne.indice - sens)
        ligneVoisine.indice = ligne.indice
        ligne.indice-=sens
      }

      if (ligne.indice === 0) {
        var idx = 1
        //Si la ligne sélectionnée n'a pas d'indice, cas d'un ajout de produit non enregistré, on redéfini les indices, les lignes à 0 en priorité, trié par fournisseur et désignation, puis les lignes avec indice
        this.lignesAAfficher().sort((a, b) => a.indice - b.indice || this.nomFournisseur(a.foId).localeCompare(this.nomFournisseur(b.foId)) || a.dAgap.localeCompare(b.dAgap)).forEach(l => {l.indice = idx++})
      }

      this.actions.disableSelectionLigneMonter(this.actions, ligne.indice === minIndice)
      this.actions.disableSelectionLigneDescendre(this.actions, ligne.indice === maxIndice)
    },
    positionnerLigneAjoutee(ligne) {
      this.lignesAAfficher().filter(l => !l.pId).forEach(l => l.indice--)
      ligne.indice = this.minIndice() - 1
    },
    executerAction(methode) {
      // Nécessaire pour faire fonctionner les actions de la barre d'action
      methode(this)
    },
    getDataCommande() {
      return _.cloneDeep(this.commande)
    },
    initialiserDonneesModifiees() {
      this.donneesInitiales = this.getDataCommande()
    },
    tarifObsolete(ligne) {
      //indique si le tarif de la ligne a été changé à l'ouverture
      return ligne.tarObs
    },
    estModifiee(ligne) {
      let ligneInitiale = this.donneesInitiales?.lignes?.find(l => l.id === ligne.id)
      if (!ligneInitiale) {
        return true
      }
      return !_.isEqual(JSON.stringify(ligne), JSON.stringify(ligneInitiale)) || ligne.tarObs
    },
    estModifieeFournisseurOuTotal(ligne) {
      let ligneInitiale = this.donneesInitiales?.lignes?.find(l => l.id === ligne.id)
      if (!ligneInitiale) {
        return true
      }
      //Si la ligne a été modifié suite à un Tarif obsolete, il ne faut pas la considérer comme modifié dans ce cas
      //ça permet d'enregistrer une commande validé avec des tarifs obsoletes sans la dévalider
      return !_.isEqual(this.ligneTotalPCB(ligne), this.ligneTotalPCB(ligneInitiale)) || !_.isEqual(ligne.foId, ligneInitiale.foId)
    },

    fournisseurEstModifie(fournisseur) {
      let fournisseurInitial = this.donneesInitiales?.detailFournisseurs?.find(f => f.fournisseurId === fournisseur.fournisseurId)
      if (!fournisseurInitial) {
        return true
      }
      return !_.isEqual(JSON.stringify(fournisseur), JSON.stringify(fournisseurInitial))
        || this.lignes(this.commande, fournisseur.fournisseurId).some(l => this.estModifieeFournisseurOuTotal(l))
        || this.nbLignes(this.commande, fournisseur.fournisseurId) !== this.nbLignes(this.donneesInitiales, fournisseur.fournisseurId)
    },
    fournisseurValideEstModifie(fournisseur) {
      let fournisseurInitial = this.donneesInitiales?.detailFournisseurs?.find(f => f.fournisseurId === fournisseur.fournisseurId)
      if (!fournisseurInitial) {
        return true
      }
      //Pour la verification au moment de l'enregistrement dans le cas des controles si fournisseur validé, on ne doit vérifier que les moidifications sur les lignes de ce fournisseur
      //Si on a changé la date de livraison, il ne faut pas dévalider le fournisseur
      return this.lignes(this.commande, fournisseur.fournisseurId).some(l => this.estModifieeFournisseurOuTotal(l))
        || this.nbLignes(this.commande, fournisseur.fournisseurId) !== this.nbLignes(this.donneesInitiales, fournisseur.fournisseurId)
    },
    ligneDesignation(ligne) {
      if (ligne.foId || !ligne.pId) {
        return ligne.dFou
      } else {
        return ligne.dAgap
      }
    },
    lignes(commande, fournisseurId) {
      let lignes = commande?.lignes?.filter(l => !l.deleted && l.qDem) || []
      if (fournisseurId) {
        lignes = lignes.filter(l => l.foId === fournisseurId)
      } else {
        lignes = lignes.filter(l => l.foId)
      }
      return lignes
    },
    lignesAAfficher() {
      return this.commande?.lignes?.filter(l => !l.deleted || this.estModifiee(l)) || []
    },
    nbLignes(commande, fournisseurId) {
      return this.lignes(commande, fournisseurId).length
    },
    nbFournisseurs(commande) {
      let fournisseurs = commande?.detailFournisseurs?.filter(f => this.nbLignes(commande, f.fournisseurId)) || []
      return fournisseurs.length
    },
    ucBesoin(ligne) {
      return ligne.utilisations?.find(u => u.uc) ? ligne.utilisations[0].uc : ligne.uc
    },
    qtePcb(ligne) {
      return qtePCB(ligne.qDem, ligne.pcb)
    },
    ligneTotalPCB(ligne) {
      return prixPCB(ligne.qDem, ligne.pcb, ligne.pu)
    },
    totalPCB(commande, fournisseurId) {
      return this.lignes(commande, fournisseurId).reduce((s, l) => s + this.ligneTotalPCB(l), 0)
    },
    ligneTotalTTC(ligne) {
      return this.ligneTotalPCB(ligne) + (this.ligneTotalPCB(ligne) * ligne.tva / 100)
    },
    totalTTC(commande) {
      return this.lignes(commande).reduce((s, l) => s + this.ligneTotalTTC(l), 0)
    },
    fournisseurSelectionne() {
      return this.commande?.detailFournisseurs?.find(f => f.fournisseurId === this.idFournisseurSelectionne)
    },
    commandeFournisseur(foId) {
      return this.commande?.detailFournisseurs?.find(f => f.fournisseurId === foId)
    },
    commandeFournisseurDansEtat(foId, etat) {
      let fournisseur = this.commandeFournisseur(foId)
      if (!fournisseur) {
        return false
      }
      return fournisseur?.etatCommande === etat
    },

    commandeFournisseurEnCreation(foId) {
      return !this.commandeFournisseurEnvoyee(foId) && !this.commandeFournisseurReceptionnee(foId)
    },
    commandeFournisseurEnvoyee(foId) {
      return this.commandeFournisseurDansEtat(foId, constantesId.etatCmdEnvoyee)
    },
    commandeFournisseurInitialeValidee(foId) {
      let fournisseurInitial = this.donneesInitiales?.detailFournisseurs?.find(f => f.fournisseurId === foId)

      if (!fournisseurInitial) {
        return false
      }
      return fournisseurInitial?.etatCommande === constantesId.etatCmdEnCours && (fournisseurInitial?.validation || fournisseurInitial?.validation != null)
    },
    commandeFournisseurReceptionnee(foId) {
      return this.commandeFournisseurDansEtat(foId, constantesId.etatCmdRecue)
    },
    commandeFournisseurEnvoyeeManuellement(foId) {
      let fournisseur = this.commandeFournisseur(foId)
      if (!fournisseur) {
        return false
      }
      return this.commandeFournisseurEnvoyee(foId) && fournisseur.dateEnvoiManuel != null
    },
    commandeFournisseurEnErreurMail(foId) {
      let fournisseur = this.commandeFournisseur(foId)
      if (!fournisseur) {
        return false
      }
      return this.commandeFournisseurEnvoyee(foId) && fournisseur.etatDuMail && fournisseur.etatDuMail.estErreur
    },
    commandeFournisseurEnCreationOuErreurMailNonTraitee(foId) {
      return this.commandeFournisseurEnCreation(foId) || (this.commandeFournisseurEnErreurMail(foId) && !this.commandeFournisseurEnvoyeeManuellement(foId))
    },
    commandeEnCreationOuErreurMailNonTraitee() {
      return this.commande?.detailFournisseurs?.some(f => this.commandeFournisseurEnCreationOuErreurMailNonTraitee(f.fournisseurId))
    },
    commandeEnvoyeeOuReceptionnee() {
      return this.commande?.detailFournisseurs?.some(f => this.commandeFournisseurEnvoyee(f.fournisseurId) || this.commandeFournisseurReceptionnee(f.fournisseurId))
    },
    commandeEnAttenteValidation() {
      //Par défaut, si l'utilisateur n'a pas l'option attenteValidation, une commande n'est jamais en attente de validation
      if (this.estAttenteValidationCommande)
        return this.commande?.commandeEnAttenteValidation && this.lignesAAfficher().length > 0
      else
        return false
    },
    commandeValide() {
      //Par défaut, si l'utilisateur n'a pas l'option attenteValidation, une commande n'est jamais validée
      if (this.estAttenteValidationCommande)
        return this.commande?.commandeValidee
      else
        return false
    },
    commandeDepuisMenu() {
      return this.commande?.depuisMenu || false
    },
    lignesCommandeSansFournisseur() {
      return this.commande?.lignes?.some(l => !l.foId || l.foId == null)
    },
    idsFournisseursVerrouilles() {
      return this.commande?.detailFournisseurs?.filter(f => this.commandeFournisseurEnvoyee(f.fournisseurId) || this.commandeFournisseurReceptionnee(f.fournisseurId))?.map(f => f.fournisseurId) || []
    },
    async chargerCommande(commandeId) {
      this.$store.commit('uxHelpers/showSpinner')
      let response = await detailsCommande(commandeId)
      this.initialiserCommande(response.data)
      this.initialiserDonneesModifiees()
      this.actions.disableSiEnvoyeOuReceptionne(this.actions, this.commandeEnvoyeeOuReceptionnee() || this.commandeValide())
      this.actions.disableDemandeValidation(this.actions, this.commandeEnAttenteValidation() || this.commandeValide() || this.commandeEnvoyeeOuReceptionnee())

      if (this.lignesAAfficher().length > 0) {
        this.actions.disableBonDeCommande(this.actions, this.estAttenteValidationCommande && !this.commandeValide())
      } else {
        this.actions.disableSiCommandeVide(this.actions, true)
      }

      this.actions.recalculer.hidden = !this.commandeDepuisMenu()
      this.$store.commit('uxHelpers/hideSpinner')
    },
    initialiserCommande(commande) {
      this.commande = commande
      this.commande?.detailFournisseurs?.forEach(f => {
        // Formatage de la date de livraison des commandes fournisseurs pour corrige un bug sur le calcul des données modifiées
        this.setDateLivraisonSouhaitee(f.fournisseurId, f.dateLivraisonSouhaitee)
      })

      this.actions.disableBonDeCommande(this.actions, this.commandeEnAttenteValidation())
    },
    idLignePourSelection(ligne) {
      // La sélection se fait sur l'ID produit / ID fournisseur pour avoir l'unicité (car l'ID de ligne n'est pas renseigné lors de l'ajout d'une nouvelle ligne dans la commande)
      return ligne.pId ? (ligne.pId || "") + (ligne.foId || "") : this.ligneDesignation(ligne)
    },
    selectionnerLigneCommande(ligne) {
      if (!ligne) {
        return
      }

      this.idLigneSelectionnee = this.idLignePourSelection(ligne)
      this.selectionnerFournisseur(ligne.foId)

      setTimeout(() => {
        document.querySelector("[data-lignepid='" + ligne.pId + "']")?.focus()
      }, 10)
    },
    selectionnerLigneFournisseur(fournisseurId) {
      this.selectionnerFournisseur(fournisseurId)
      if (this.idFournisseurSelectionne) {
        document.getElementById("fourn" + this.idFournisseurSelectionne)?.focus()
      }
    },
    selectionnerFournisseur(fournisseurId) {
      if (this.filtrerLignesSurFournisseur && fournisseurId !== this.idFournisseurSelectionne) {
        this.idLigneSelectionnee = null
      }
      this.idFournisseurSelectionne = fournisseurId
    },
    idUtilisationPourSelection(utilisation) {
      return utilisation.composanteId
    },
    selectionnerUtilisation(utilisation) {
      this.idUtilisationSelectionnee = this.idUtilisationPourSelection(utilisation)
    },
    activerFiltreLignesSurFournisseurs() {
      if (this.fournisseurSelectionne()?.fournisseurId !== this.ligneSelectionnee?.foId) {
        this.idLigneSelectionnee = null
      }
    },
    trouverLigneCommande(sens) {
      if (!this.lignesFiltreesTrieesAAfficher?.length || !sens) {
        return null
      }
      let iLigne = this.lignesFiltreesTrieesAAfficher.findIndex(l => this.idLignePourSelection(l) === this.idLignePourSelection(this.ligneSelectionnee))

      let ligne = null
      do {
        iLigne += sens
        if (iLigne < 0) {
          iLigne = this.lignesFiltreesTrieesAAfficher.length - 1
        } else if (iLigne >= this.lignesFiltreesTrieesAAfficher.length) {
          iLigne = 0
        }
        ligne = this.lignesFiltreesTrieesAAfficher[iLigne]
      } while (!this.commandeFournisseurEnCreation(ligne.foId) || !ligne?.pId)

      return ligne
    },
    deplacerSelectionLigneCommande(sens) {
      let iSens = sens.direction === "up" ? -1 : sens.direction === "down" || sens.direction === "enter" ? 1 : 0
      let ligne = this.trouverLigneCommande(iSens)
      if (ligne) {
        this.selectionnerLigneCommande(ligne)
      }
    },
    trouverLigneFournisseur(sens) {
      if (!this.fournisseursAAfficher?.length || !sens) {
        return null
      }
      let iLigne = this.fournisseursAAfficher.findIndex(l => l.fournisseurId === this.idFournisseurSelectionne)

      let ligne = null
      do {
        iLigne += sens
        if (iLigne < 0) {
          iLigne = this.fournisseursAAfficher.length - 1
        } else if (iLigne >= this.fournisseursAAfficher.length) {
          iLigne = 0
        }
        ligne = this.fournisseursAAfficher[iLigne]
      } while (!this.commandeFournisseurEnCreation(ligne.fournisseurId) || !this.nbLignes(this.commande, ligne.fournisseurId))

      return ligne
    },
    deplacerSelectionFournisseur(e) {
      let sens = 0
      if (e.key === "ArrowUp") {
        sens = -1
      } else if (e.key === "ArrowDown" || e.key === "Enter") {
        sens = 1
      }
      let ligne = this.trouverLigneFournisseur(sens)
      if (ligne) {
        this.selectionnerLigneFournisseur(ligne.fournisseurId)
      }
    },
    showAjoutArticles() {
      this.commande.total = arrondiNombre(this.totalPCB(this.commande), 2)
      this.$refs.modalAjoutProduits.show()
    },
    ajouterArticles(articles) {
      this.commande.lignes.filter(l => l.pId && !articles.find(a => a.pId === l.pId)).forEach(l => {
        l.deleted = true
      })
      articles.forEach(a => {
        let ligne = this.commande.lignes.find(l => l.pId === a.pId)
        if (ligne) {
          if (ligne.deleted) {
            this.positionnerLigneAjoutee(ligne)
          }
          if (ligne.tId !== a.tId || ligne.qDem !== a.qDem) {
            ligne.foId = a.foId
            ligne.tId = a.tId
            ligne.qDem = arrondiNombre(a.qDem, 2)
            ligne.dAgap = a.dAgap
            ligne.dFou = a.dFou
            ligne.Ref = a.Ref
            ligne.marque = a.marque
            ligne.pcb = a.pcb
            ligne.uc = a.uc
            ligne.pu = a.pu
            ligne.deleted = false
          }
        } else {
          this.positionnerLigneAjoutee(a)
          a.deleted = false
          this.commande.lignes.unshift(a)
        }
        this.ajouterDetailFournisseurSiAbsent(a.foId)
      })
    },
    ajouterDetailFournisseurSiAbsent(fournisseurId) {
      if (fournisseurId && this.commande?.detailFournisseurs && !this.commande?.detailFournisseurs?.find(f => f.fournisseurId === fournisseurId)) {
        this.commande.detailFournisseurs.push({ fournisseurId: fournisseurId, bonDeCommande: null, dateLivraisonSouhaitee: null })
      }
    },
    async recalculer() {
      this.$store.commit('uxHelpers/showSpinner')
      let response = await recalculerCommande(this.commande)
      this.initialiserCommande(response.data)
      this.$store.commit('uxHelpers/hideSpinner')
    },
    async optimiserpcb() {
      this.$store.commit('uxHelpers/showSpinner')
      let response = await optimiserpcbCommande(this.commande)
      this.initialiserCommande(response.data)
      this.$store.commit('uxHelpers/hideSpinner')
    },
    async optimiserfournisseur() {
      this.$store.commit('uxHelpers/showSpinner')
      let response = await optimiserfournisseurCommande(this.commande)
      this.initialiserCommande(response.data)
      this.$store.commit('uxHelpers/hideSpinner')
    },
    async optimiserfranco() {
      this.$store.commit('uxHelpers/showSpinner')
      let response = await optimiserfrancoCommande(this.commande)
      this.initialiserCommande(response.data)
      this.$store.commit('uxHelpers/hideSpinner')
    },
    effacerAlerteRecalcul(ligne) {
      ligne.uModifiees = false
    },
    qteDemChangee(ligne, ancienneValeur) {
      if (ligne.qDem != ancienneValeur) {
        ligne.deleted = false
        this.effacerAlerteRecalcul(ligne)
      }
    },
    ucIdentiques(uc1, uc2) {
      return uc1?.toLowerCase() === uc2?.toLowerCase()
    },
    showAjoutProduitHM(ligne) {
      if (ligne.recIngId) {
        this.selectionnerLigneCommande(ligne)
        this.$refs.modalAjoutProduit.show({ ingredientId: ligne.recIngId, nomIngredient: ligne.dFou })
      } else {
        this.alerteErreur("Impossible d'ouvrir la création de produit car l'ingrédient de cette ligne n'a pas pu être retrouvé. Merci de cliquer sur le bouton 'Recalculer Besoins'")
      }
    },
    showSubstitutionArticle(ligne) {
      this.selectionnerLigneCommande(ligne)
      if (ligne.ingId || ligne.recIngId) {
        let article = {
          ingredientId: ligne.ingId || ligne.recIngId,
          familleId: ligne.faId,
          produitId: ligne.pId,
          tarifId: ligne.tId,
          //grammage: ligne.utilisations?.find(u => u.grammage)?.grammage || null,
          grammage: null,
          quantiteBesoinEnUC: ligne.qDem,
          pcb: ligne.pcb,
          uc: ligne.uc,
          designation: ligne.dAgap,
          reference: ligne.Ref,
          marque: ligne.marque,
          fournisseurId: ligne.foId,
          prixUnitaire: ligne.pu
        }
        this.$refs.modalSubstitutionArticle.show(article)
      } else {
        this.alerteErreur("Le produit " + ligne.dAgap + " n'est associé à aucun ingrédient. S'il s'agit d'un produit hors marché, veuillez le corriger dans le menu : Commandes -> Produits hors marché. Sinon, veuillez contacter Agap'pro")
      }
    },
    fusionnerLignesCommande(ligneConservee, ligneSupprimee) {
      ligneConservee.qDem = arrondiNombre(ligneConservee.qDem + ligneSupprimee.qDem, 2)
      this.commande.lignes = this.commande.lignes.filter(l => this.idLignePourSelection(l) !== this.idLignePourSelection(ligneSupprimee))
    },
    async affecterNouveauProduitHM(article) {
      if (!article.produits?.length || !article.produits[0].tarifs?.length) {
        this.alerteErreur("Le produit n'a pas pu être affecté automatiquement à la ligne de commande. Merci de le sélectionner manuellement.")
        return
      }

      let produit = article.produits[0]
      let tarif = produit.tarifs[0]

      let art = { produitId: produit.produitId }
      art.produit = {
        fournisseurId: tarif.fournisseurId,
        tarifId: tarif.tarifId,
        familleId: produit.familleId,
        reference: tarif.reference,
        marque: tarif.marque,
        pcb: tarif.pcb,
        uc: produit.uc,
        prixUnitaire: tarif.pu,
        designation: tarif.designation || produit.designation
      }

      this.substituerArticle(art)
    },
    substituerArticle(article) {
      let ligne = this.ligneSelectionnee
      let produit = article.produit

      if (article.produitId && produit) {
        if (article.produitId !== ligne.pId) {
          // Changement de produit : fusion potentielle
          let doublon = this.lignesAAfficher().find(l => l.pId === article.produitId)
          if (doublon) {
            // Doublon trouvé : fusion des lignes de commandes
            if (!doublon.utilisations?.length) {
              // le doublon ne possède pas d'utilisations (menus), il peut être fusionné avec la ligne sélectionnée puis supprimé
              this.fusionnerLignesCommande(ligne, doublon)
            } else if (!ligne.utilisations?.length) {
              // Le doublons possède des utilisations, mais la ligne sélectionnée n'en possède pas, c'est donc la ligne sélectionnée qui est sacrifiée
              this.fusionnerLignesCommande(doublon, ligne)
              ligne = doublon
            }
            // Remarque : en cas d'utilisations sur la ligne sélectionnée et sur le doublon, on conserve les deux lignes (il n'y a donc pas de fusion)
            // => à voir à l'usage si on décide de fusionner les lignes de commande et les utilisations
          }
        }

        if (!ligne.pId) {
          // On a sélectionné un produit sur un ingrédient sans cotation :
          // On applique la quantité de besoin sur la quantité demandée car elle n'est pas renseignée à ce moment-là
          ligne.qDem = ligne.qBesoin
          // Puis on intervertit cette ligne avec le dernier ingrédient sans cotation pour que les ingrédients sans cotation restent groupés en début de tableau
          if (ligne.indice < this.minIndice() - 1) {
            let ligneAIntervertir = this.lignesAAfficher().find(l => l.indice === this.minIndice() - 1)
            if (ligneAIntervertir) {
              ligneAIntervertir.indice = ligne.indice
              ligne.indice = this.minIndice() - 1
            }
          }
        }

        ligne.pId = article.produitId
        ligne.foId = produit.fournisseurId
        ligne.tId = produit.tarifId
        ligne.faId = produit.familleId
        ligne.Ref = produit.reference
        ligne.marque = produit.marque
        ligne.pcb = produit.pcb
        ligne.uc = produit.uc
        ligne.pu = produit.prixUnitaire
        ligne.deleted = false
        if (ligne.foId) {
          ligne.dFou = produit.designation
        } else {
          ligne.dAgap = produit.designation
        }
        if (!ligne.ingId && ligne.recIngId) {
          ligne.ingId = ligne.recIngId
        }
      }
      this.ajouterDetailFournisseurSiAbsent(ligne.foId)
    },
    supprimerLigne(ligne) {
      this.idLigneSelectionnee = null // Nécessaire pour corriger un bug de rafraichissement du tableau (la ligne ne se barre pas correctement lors du clic sur le bouton de suppression de la ligne)
      this.selectionnerLigneCommande(ligne)
      ligne.deleted = true
      ligne.qDem = 0
      this.effacerAlerteRecalcul(ligne)
    },
    setDateLivraisonSouhaitee(fournisseurId, date) {
      if (date) {
        let dt = new Date(this.formatDateAvecTirets(date))
        let fournisseur = this.commandeFournisseur(fournisseurId)
        if (fournisseur) {
          fournisseur.dateLivraisonSouhaitee = dt
        }
      }
    },
    attributsDPsCommande(fournisseurId) {
      let fournisseur = this.commandeFournisseur(fournisseurId)
      if (!fournisseur || !fournisseur.joursLivraison) {
        return []
      }
      return [{
        dates: {weekdays: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"].map((j, n) => {
          if (fournisseur.joursLivraison.indexOf(j) > -1) {
            return n + 1
          }
        })},
        highlight: "green",
      }]
    },
    async genererBdC() {
      if (!this.alerteEnregistrementObligatoire() && await this.alerteValiderIngredientManquant()) {
        this.$refs.modalImpressionCommande.show()
      }
    },
    async choisirFournisseur() {
        this.$refs.modalChoisirFournisseur.show()
    },
    optionsBdCParDefaut(commandeId, fournisseurId) {
      return {
          CommandeId: commandeId,
          DateImpression: new Date(),
          SortieXlsx: true,
          SortieEnPDF: false,
          A3: false,
          ImprimerControleAReception: true,
          ImprimerTitreCommande: true,
          TrierAlpha: false,
          ImprimerTVA: false,
          ImprimerDatesConso: false,
          FournisseurIds: [ fournisseurId ],
          emetteur: this.utilisateurCourant?.etablissements?.find(x => x.eId === this.etabCourantId)?.email,
          message: constantesId.messageDefautEnvoiCommande.replace(/\n/g, "<br />")
        }
    },
    async telechargerBdC(fournisseurId) {
      let response = await obtenirBC(this.commande.id, this.optionsBdCParDefaut(this.commande.id, fournisseurId))
      saveAs(new File([response.data], this.commande.nom + ".xlsx", { type: "text/plain;charset=utf-8" }))
    },
    async alerteValiderIngredientManquant() {
      if (this.lignesAAfficher().some(l => !l.pId) && !this.commandeEnvoyeeOuReceptionnee()) {
        return await this.alerteConfirmation("<span style='color:red'>Erreurs dans la liste</span>", "Attention, tous les besoins sur la période indiquée n'ont pas pu être satisfaits, la commande calculée ne vous permettra donc pas en l'état de tout produire.<br/>N'oubliez pas de commander par ailleurs ce qui vous manque.<br/><br/>Attention, après avoir envoyé la commande, il ne vous sera plus possible de la recalculer.<br/><br/>Souhaitez-vous envoyer la commande ?")
      }
      return true
    },
    async ouvrirEnvoiSansMail() {
      if (this.alerteEnregistrementObligatoire()) {
        return
      }
      this.$refs.modalEnvoiCommande.show()
    },
    alerteEnregistrementObligatoire() {
      if (this.donneesModifiees) {
        this.alerteErreur("Vous ne pouvez pas faire cette action car la commande est en cours de modification.<br/><br/>Vous devez cliquer sur le bouton <b>Enregistrer</b> ou le bouton <b>Annuler</b> pour pouvoir accéder à cette fonctionnalité.")
        return true
      }
      return false
    },
    alerteLigneSansFournisseur() {
      if (this.lignesCommandeSansFournisseur()) {
        this.alerteErreur("Vous ne pouvez pas demander la validation car la commande contient des lignes sans fournisseurs.")
        return true
      }
      return false
    },
    async envoyerBdCParMail(fournisseurId) {
      this.idFournisseurSelectionne = fournisseurId
      if (this.alerteEnregistrementObligatoire()) {
        return
      }
      if (!this.commandeFournisseurEnCreationOuErreurMailNonTraitee(fournisseurId)) {
        // Pour les fournisseurs dont le bon de commande a déjà été envoyé, le bon de commande est directement téléchargé sans envoi automatique par email
        this.telechargerBdC(fournisseurId)
        return
      }
      if (!this.commandeFournisseur(fournisseurId)?.dateLivraisonSouhaitee) {
        // La date de livraison n'a pas été renseignée, la commande ne peut pas être envoyée
        this.alerteErreur("La date de livraison est obligatoire.")
        return
      }
      if (!this.environnementProduction) {
        // Les envois de commandes par mail aux fournisseurs sont désactivés sur les environnements de test
        this.alerteEnvironnementTest()
        return
      }
      if (!this.utilisateurCourant?.etablissements?.find(x => x.eId === this.etabCourantId)?.email) {
        this.alerteErreur("Aucun mail n'est configuré sur votre établissement. Veuillez configurer votre adresse mail dans la page de profil pour pouvoir utiliser cette fonctionnalité.")
        return
      }
      let result = await this.alerteConfirmation("Envoyer la commande fournisseur par email", "Le bon de commande va être envoyé par email à '" + this.nomFournisseur(fournisseurId) + "'.", "Envoyer", "Annuler")
      if (result) {
        // Téléchargement du bon de commande généré
        this.telechargerBdC(fournisseurId)

        if (await this.alerteValiderIngredientManquant()) {
          // Envoi automatique par mail du bon de commande généré
          this.$store.commit('uxHelpers/showSpinner')
          await envoyerBC(this.commande.id, this.optionsBdCParDefaut(this.commande.id, fournisseurId))
          this.chargerCommande(this.commande.id)
          this.$store.commit('uxHelpers/hideSpinner')
          this.alerteSucces("Bon de commande envoyé !")
        }
      }
    },
    async envoyerBdCSansMail(fournisseurId) {
      this.idFournisseurSelectionne = fournisseurId
      if (this.alerteEnregistrementObligatoire()) {
        return
      }
      if (!this.commandeFournisseur(fournisseurId)?.dateLivraisonSouhaitee) {
        // La date de livraison n'a pas été renseignée, la commande ne peut pas être envoyée
        this.alerteErreur("La date de livraison est obligatoire.")
        return
      }
      let result = await this.alerteConfirmation("Passer la commande au statut 'Envoyé'", "La commande '" + this.nomFournisseur(fournisseurId) + "' va être considérée comme envoyée au fournisseur.<br/><br/>Cette fonctionnalité est à utiliser lorsque vous avez passé votre commande par téléphone ou que vous avez envoyé le bon de commande manuellement.<br/><br/>Cette action est irréversible.")
      if (result) {
        if (await this.alerteValiderIngredientManquant()) {
          this.$store.commit('uxHelpers/showSpinner')
          await envoyerCommandeFournisseurs(this.commande.id, [fournisseurId])
          this.chargerCommande(this.commande.id)
          this.$store.commit('uxHelpers/hideSpinner')
          this.alerteSucces("Commande fournisseur passée au statut 'Envoyée' !")
        }
      }
    },
    ouvrirDetailCommandeFournisseur(fournisseurId) {
      this.idFournisseurSelectionne = fournisseurId
      if (this.commandeFournisseurEnvoyee(fournisseurId) && this.inventaireOuvert) {
        this.alerteErreur("Cette fonctionnalité est verrouillée car un inventaire est en cours de saisie.<br/><br/>Veuillez aller dans le menu Stock => Inventaire pour valider ou annuler l'inventaire en cours.")
        return
      }
      if (!this.alerteEnregistrementObligatoire()) {
        this.$router.push({ name: 'DetailCommandeFournisseur', params: { etablissementId: this.etabCourantId, commandeId: this.commande.id, fournisseurId: fournisseurId } })
      }
    },
    async supprimerFournisseur(fournisseurId) {
      this.$store.commit('uxHelpers/showSpinner')
      let response = await supprimerUnFournisseur(fournisseurId, this.commande)
      this.initialiserCommande(response.data)
      this.$store.commit('uxHelpers/hideSpinner')
    },
    async enregistrer() {
      let ok = true
      //Pour les utilisateur avec l'option de validation de commande, on met à jour l'info de validation à null pour les fournisseurs déjà validé, avec des modifications
      //Si la commande est déjà validée, il n'est pas possible de la changer, donc normalement on ne trouvera jamais de différences
      if (this.estAttenteValidationCommande) {
        let modifValidation=false

        this.commande.detailFournisseurs.filter(d => this.fournisseurValideEstModifie(d)).forEach(x => {
          modifValidation= (modifValidation || x.validation || x.validation != null) ? true : false
          x.validation = null
        })

        if (modifValidation && this.commandeEnAttenteValidation()) {
          ok = await this.alerteConfirmation("Modifier la commande", "Attention, vous modifiez la commande d'un fournisseur déjà validé.</br>Celui-ci devra de nouveau être validé par votre gestionnaire.</br>Voulez-vous continuer ?")
        }
      }

      if (ok) {
        this.$store.commit('uxHelpers/showSpinner')
        await modifierCommande(this.commande.id, this.commande)
        this.alerteSucces('Commande modifiée !')
        this.chargerCommande(this.commande.id)
        this.$store.commit('uxHelpers/hideSpinner')
      }
      
    },
    async verifierInventaire() {
      this.inventaireOuvert = await verifierInventaireOuvert(this.etabCourantId)
    },
    async demandeValidationCommande() {
      if (this.alerteEnregistrementObligatoire()) {
        return
      }
      if (this.alerteLigneSansFournisseur()) {
        return
      }
      let result = await this.alerteConfirmation("Valider la commande", "Souhaitez-vous demander la validation de cette commande ?")
      if (result) {
        this.$store.commit('uxHelpers/showSpinner')
        await demanderValidationCommande(this.commande.id)
        this.alerteSucces('Commande envoyée en attente de validation !')
        this.chargerCommande(this.commande.id)
        this.$store.commit('uxHelpers/hideSpinner')
      }
    },
    mettreAJourRecettes(commande, recette) {
      commande.lignes.forEach(l => {
        l.utilisations.filter(u => u.recette.id === recette.id).forEach(u => {
          u.recette.estDeclinaisonPersonnalisee = recette.estDeclinaisonPersonnalisee
        })
      })
    },
    async recetteEnregistree(donnees) {
      // Mise à jour des alertes de vérification des recettes par l'utilisateur sur les recettes de la commande
      this.mettreAJourRecettes(this.commande, donnees.recette)
      this.mettreAJourRecettes(this.donneesInitiales, donnees.recette)
    },
    async recetteEnregistreeModifiee() {
      // Exécution automatique du recalcul des besoins
      if (!this.commandeEnvoyeeOuReceptionnee()) {
        let result = await this.alerteConfirmation("Recette modifiée", "Voulez-vous recalculer votre commande pour prendre en compte les modifications appliquées à la recette ?")
        if (result) {
          this.recalculer()
        }
      }
    }
  },
  mounted() {
    this.actions.initialiser(this)
    this.chargerCommande(this.$route.params.commandeId)
    this.verifierInventaire()
  },
}
</script>
